import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { useWebTitle } from 'hooks/useWebTitle';

import { PageLayout } from 'components/PageLayout';
import { Result } from 'components/Result';
import { ContactForm } from './ContactForm';
import { ContactIcon } from 'icons';

export const Contact = (): JSX.Element => {
  const [contactFormCompleted, setContactFormCompleted] = useState(false);
  const { t } = useTranslation();
  useWebTitle('contact');

  const items = [{ icon: <ContactIcon />, label: t('menu:contact') }];

  const handleContactFormCompleted = () => {
    setContactFormCompleted(prevState => !prevState);
  };

  return (
    <PageLayout items={items}>
      {contactFormCompleted ? (
        <Result
          status='success'
          title={t('contact:emailSent')}
          subTitle={t('contact:emailSentSubtext')}
          extra={[
            <Button type='primary' key='next-msg' onClick={handleContactFormCompleted}>
              {t('contact:sendAnotherMail')}
            </Button>,
          ]}
        />
      ) : (
        <ContactForm handleContactFormCompleted={handleContactFormCompleted} />
      )}
    </PageLayout>
  );
};
