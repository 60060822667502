import { useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IUserAdminPanel } from '../types';

import { useWindowSize } from 'hooks/useWindowSize';
import { formatIsoToDisplay } from 'helpers/dates';

import { EditDropdown } from './EditDropdown';

import { ListItemContainer, PropertyName, PropertyCol, InformationCol, PropertiesCol, ActionCol } from '../styles';

export interface ListItemProps {
  item: IUserAdminPanel;
}

export const ListItem = ({ item }: ListItemProps): JSX.Element => {
  const { t } = useTranslation();

  const size = useWindowSize();

  const isMobileResolution: boolean = useMemo(() => (size?.width ? size.width < 768 : false), [size.width]);

  return (
    <ListItemContainer>
      <Row>
        <InformationCol md={22} xs={23}>
          <Row wrap={true}>
            <Col md={6} xs={24}>
              <Typography.Title level={2} ellipsis={isMobileResolution ? { rows: 2 } : true}>
                {item.name}
              </Typography.Title>
            </Col>

            <PropertiesCol md={10} xs={12}>
              <Row>
                <PropertyCol md={12} xs={24}>
                  <PropertyName>{t('adminPanel:users.email')}: </PropertyName>
                  {item.email}
                </PropertyCol>
                <PropertyCol md={6} xs={24}>
                  <PropertyName>{t('adminPanel:users.role')}: </PropertyName>
                  {t(`adminPanel:users.userRole.${item.role}`, { defaultValue: '' })}
                </PropertyCol>
                <PropertyCol md={6} xs={24}>
                  <PropertyName>{t('adminPanel:users.apiKey')}: </PropertyName>
                  {t(`adminPanel:users.apiKeyStatus.${item.apiKey}`, { defaultValue: '' })}
                </PropertyCol>
              </Row>
            </PropertiesCol>

            <PropertyCol md={8} xs={12}>
              <Row>
                <PropertyCol md={6} xs={24}>
                  <PropertyName>{t('adminPanel:users.limit')}: </PropertyName>
                  {item.limit}
                </PropertyCol>
                <PropertyCol md={6} xs={24}>
                  <PropertyName>{t('adminPanel:users.status')}: </PropertyName>
                  {t(`adminPanel:users.userStatus.${item.status}`, { defaultValue: '' })}
                </PropertyCol>
                <PropertyCol md={12} xs={24}>
                  <PropertyName>{t('adminPanel:users.registerDate')}: </PropertyName>
                  {formatIsoToDisplay(item.createdAt)}
                </PropertyCol>
              </Row>
            </PropertyCol>
          </Row>
        </InformationCol>

        <ActionCol md={2} xs={1}>
          <EditDropdown status={item.status} role={item.role} userId={item._id} />
        </ActionCol>
      </Row>
    </ListItemContainer>
  );
};
