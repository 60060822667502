import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import type { IImageConnected } from 'models/Image';
import type { Size } from 'hooks/useWindowSize';

import { useWindowSize } from 'hooks/useWindowSize';
import { THUMBS_URL } from 'config/settings';
import { routes } from 'app/router/routes';

import { ConnectedImagePreview, ConnectedImagesContainer, LinkAccessible, StyledSwiper } from './styles';

interface ImageConnectedProps {
  connectedImages: IImageConnected[];
}

export const ImageConnected = ({ connectedImages }: ImageConnectedProps): JSX.Element | null => {
  const { t } = useTranslation();

  const size: Size = useWindowSize();

  const imagesCount = useMemo(() => {
    if (connectedImages && size.width) {
      return size.width / 80 > connectedImages.length ? connectedImages.length : size.width / 80;
    }
  }, [size, connectedImages]);

  if (!connectedImages) {
    return null;
  }

  return (
    <ConnectedImagesContainer gutter={12} justify='center'>
      <StyledSwiper slidesPerView={imagesCount} modules={[Navigation]} navigation>
        {connectedImages.map(image => (
          <SwiperSlide key={image._id}>
            <LinkAccessible to={routes.image(image._id)}>
              <Col>
                {image.thumbPath ? (
                  <ConnectedImagePreview
                    src={`${THUMBS_URL}/${image.thumbPath}`}
                    alt={t('images:connectedImage', { id: image._id })}
                  />
                ) : (
                  t('images:noPreview')
                )}
              </Col>
            </LinkAccessible>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </ConnectedImagesContainer>
  );
};
