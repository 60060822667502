import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import { ColHeader, ListHeaderContainer } from '../styles';

export const ListHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ListHeaderContainer>
      <Col md={22}>
        <Row>
          <ColHeader md={6}>{t('adminPanel:apiKeys.name')}</ColHeader>

          <Col md={9}>
            <Row>
              <ColHeader md={14}>{t('adminPanel:apiKeys.email')}</ColHeader>
              <ColHeader md={10}>{t('adminPanel:apiKeys.status')}</ColHeader>
            </Row>
          </Col>

          <Col md={9}>
            <Row>
              <ColHeader md={12}>{t('adminPanel:apiKeys.applicationDate')}</ColHeader>
              <ColHeader md={12}>{t('adminPanel:apiKeys.decisionDate')}</ColHeader>
            </Row>
          </Col>
        </Row>
      </Col>

      <ColHeader md={2}>{t('adminPanel:apiKeys.actions')}</ColHeader>
    </ListHeaderContainer>
  );
};
