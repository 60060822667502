import http from 'helpers/http';

import type { IUser } from 'models/Auth';
import type { IApiKey, IApiKeyRequest, IBasicInformation, IPasswordChange } from './types';

export const serviceAccountPanel = {
  basicInformation: (data: IBasicInformation) => http.put<IUser>('/users/account', data),
  passwordChange: (data: IPasswordChange) => http.put<IUser>('/users/password-change', data),
  requestApiKey: (data: IApiKeyRequest) => http.post<IUser>('/api-key', data),
  getApiKey: () => http.get<IApiKey>('/api-key'),
};
