import { API_URL } from 'config/settings';
import { getDefaultLanguage } from 'config/i18n';

import { downloadErrorHandler, httpErrorHandler } from './errorHandler';
import { getAuthToken } from './auth';

type RequestType = 'get' | 'post' | 'put' | 'delete';

export type ErrorResponse = { message: string; name: string; errorCode: number[]; status: number };

export type ResponseType<T> = { status: 'success'; data: T } | ErrorResponse;

const customHeader = (isFormData: boolean) => {
  const lang = getDefaultLanguage();

  const header = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${getAuthToken()}`,
    'Accept-Language': lang,
  });

  if (!isFormData) {
    header.set('Content-Type', 'application/json');
  }
  return header;
};

const processData = (data?: object) => {
  if (!data) {
    return undefined;
  } else if (data instanceof FormData) {
    return data;
  }

  return JSON.stringify(data);
};

const fetchData = async (method: RequestType, url: string, data?: object): Promise<Response> => {
  const isFormData = data instanceof FormData;
  const response = await fetch(`${API_URL}${url}`, {
    method,
    headers: customHeader(isFormData),
    body: processData(data),
  });

  if (response.ok) {
    return response;
  }

  throw await httpErrorHandler(response);
};

const processJSON = <ResponseObjectType>(response: Response) => {
  return response.json() as Promise<ResponseObjectType>;
};

const processDownload = async (url: string) => {
  const downloadUrl = `${API_URL}${url}`;

  const response = await fetch(downloadUrl, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getAuthToken()}`,
    }),
  });

  if (response.ok) {
    const data = (await response.json()) as { hash: string };
    const a = document.createElement('a');

    const dataUrl = downloadUrl.concat(`/${data.hash}`);

    a.href = dataUrl;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    return;
  }

  throw await downloadErrorHandler(response);
};

const apiWrapper =
  (method: RequestType) =>
  async <ResponseObjectType>(url: string, data?: object) => {
    const response = await fetchData(method, url, data);
    return await processJSON<ResponseObjectType>(response);
  };

class HTTP {
  static get = apiWrapper('get');
  static post = apiWrapper('post');
  static put = apiWrapper('put');
  static delete = apiWrapper('delete');
  static downloadHash = async (url: string) => await processDownload(url);
}

export default HTTP;
