import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { IUserLogin, IUserRegister } from 'models/Auth';

import { serviceAuth } from './service';
import { setAuthToken } from 'helpers/auth';
import { routes } from 'app/router/routes';

export const useLoginMutation = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: IUserLogin) => serviceAuth.login(data),
    onSuccess: data => {
      setAuthToken(data.token);
      navigate(routes.images());
    },
  });
};

export const useRegisterMutation = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: IUserRegister) => serviceAuth.register(data),
    onSuccess: () => {
      navigate(routes.activateAccount());
    },
  });
};

export const useWhoAmIQuery = () =>
  useQuery({
    queryKey: ['auth', 'whoami'],
    queryFn: () => serviceAuth.whoAmI(),
    retry: false,
    staleTime: 60 * 60,
  });
