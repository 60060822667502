import type { IPassword, IPasswordConfirm, IUser } from 'models/Auth';

export type IBasicInformation = Pick<IUser, 'email' | 'firstName' | 'lastName'>;

export interface IPasswordChange extends IPassword, IPasswordConfirm {
  oldPassword: string;
}

export interface IApiKey {
  user: Omit<IUser, 'createdAt' | 'updatedAt'>;
  dataPurpose: DataPurpose[];
  createdAt: string;
  updatedAt: string;
  keyValue: string | null;
  status: ApiKeyStatus;
  dataPurposeOtherDescription?: string;
  companyName?: string;
  jobTitle?: string;
}

export type IApiKeyRequest = Pick<IApiKey, 'dataPurpose' | 'dataPurposeOtherDescription' | 'companyName' | 'jobTitle'>;

export enum DataPurpose {
  BIOMETRIC = 'biometric',
  SCIENCE = 'science',
  DIDACTIC = 'didactic',
  SELF_EDUCATION = 'selfEducation',
  DEVELOPMENT_STAFF = 'developmentStaff',
  DEVELOPMENT_DIAGNOSTICS = 'developmentDiagnostics',
  NEW_TECHNOLOGIES = 'newTechnologies',
  DEVELOPMENT_INNOVATION = 'developmentInnovation',
  STATISTIC = 'statistic',
  COMMERCIAL = 'commercial',
  OTHERS = 'others',
}

export enum ApiKeyStatus {
  PENDING = 'pending',
  GRANTED = 'granted',
  DECLINED = 'declined',
  BLOCKED = 'blocked',
}
