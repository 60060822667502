import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { PageLayout } from 'components/PageLayout';
import { Container } from 'config/globalStyles';

import { useWebTitle } from 'hooks/useWebTitle';
import { useWhoAmIQuery } from 'pages/Auth/queries';
import { routes } from 'app/router/routes';

import { ActionButton, ApiKeyInformationContainer, ApiKeyInformationTitle, TextContainer } from './style';
import { KeyIcon } from 'icons';

export const ApiKeyInformation = (): JSX.Element => {
  const { t } = useTranslation();
  useWebTitle('apiKeyInformation');
  const { data: user } = useWhoAmIQuery();

  const items = [{ icon: <KeyIcon />, label: t('menu:apiKey') }];

  return (
    <>
      {user ? (
        <Navigate to={routes.apiKey()} />
      ) : (
        <ApiKeyInformationContainer>
          <PageLayout items={items} hideUserDropdown>
            <Container size='large'>
              <TextContainer>
                <ApiKeyInformationTitle>{t('apiKeyInformation:title')}</ApiKeyInformationTitle>
                <article>
                  <p>{t('apiKeyInformation:loginInfo')}</p>
                  <ActionButton to={routes.login()}>{t('auth:login')}</ActionButton>
                </article>
                <article>
                  <p>{t('apiKeyInformation:registerInfo')}</p>
                  <ActionButton to={routes.register()}>{t('auth:register')}</ActionButton>
                </article>
              </TextContainer>
            </Container>
          </PageLayout>
        </ApiKeyInformationContainer>
      )}
    </>
  );
};
