import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import type { IApiKey, IApiKeyRequest } from '../types';
import type { SubmitHandler } from 'react-hook-form';

import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';

import { DataPurpose } from '../types';

import { useApiKeyMutation } from '../queries';
import { useValidation } from 'hooks/useValidation';
import { useWebTitle } from 'hooks/useWebTitle';

import { ActionButton, ActionButtonsContainer, ApiKeyDescription } from '../style';

interface IDataPurposeSelect {
  label: string;
  value: DataPurpose;
}

export const FormApiKey = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<IApiKeyRequest>();
  const { t } = useTranslation();
  const { validate } = useValidation();
  const { mutate: basicInformationSubmit, isPending } = useApiKeyMutation();
  useWebTitle('apiKey');
  const options: IDataPurposeSelect[] = [];

  const onSubmit: SubmitHandler<IApiKeyRequest> = data => {
    basicInformationSubmit(data);
  };

  Object.values(DataPurpose).forEach(dataPurpose => {
    const option = { label: t(`accountPanel:apiKey.dataPurposeOptions.${dataPurpose}`), value: dataPurpose };
    options.push(option);
  });

  return (
    <Form<IApiKey> onSubmit={handleSubmit(onSubmit)}>
      <ApiKeyDescription>{t('accountPanel:apiKey.states.noApiKey')}</ApiKeyDescription>
      <Select
        name='dataPurpose'
        label={t('accountPanel:apiKey.dataPurpose')}
        control={control}
        errors={errors}
        rules={validate('required')}
        options={options}
        restProps={{ mode: 'multiple', showArrow: true, allowClear: true }}
      />
      {watch('dataPurpose')?.includes(DataPurpose.OTHERS) && (
        <Input
          name='dataPurposeOtherDescription'
          label={t('accountPanel:apiKey.dataPurposeOtherDescription')}
          control={control}
          errors={errors}
          rules={validate('required')}
        />
      )}
      <Input name='companyName' label={t('accountPanel:apiKey.companyName')} control={control} errors={errors} optional />
      <Input name='jobTitle' label={t('accountPanel:apiKey.jobTitle')} control={control} errors={errors} optional />

      <ActionButtonsContainer>
        <ActionButton type='primary' htmlType='submit' loading={isPending}>
          {t('accountPanel:apiKey.save')}
        </ActionButton>
      </ActionButtonsContainer>
    </Form>
  );
};
