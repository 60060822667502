import { Layout } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import GlobalStyle from 'config/globalStyles';
import { themeDefault } from 'config/theme';

import { ConfigProvider } from 'components/ConfigProvider';
import { browserRouter } from './router';

const queryClient = new QueryClient({});

export const App = (): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={themeDefault}>
      <ConfigProvider>
        <Layout>
          <RouterProvider router={browserRouter} />
        </Layout>
      </ConfigProvider>
      <GlobalStyle />
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
