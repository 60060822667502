import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useWebTitle } from 'hooks/useWebTitle';

import { ImagesList } from './components/ImagesList';
import { PageLayout } from 'components/PageLayout';
import { Container } from 'config/globalStyles';

import { ListIcon } from 'icons';

export const Images = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  useWebTitle('images', searchParams.get('page') ?? '1');

  const items = [{ icon: <ListIcon />, label: t('menu:list') }];

  return (
    <PageLayout items={items}>
      <Container>
        <ImagesList />
      </Container>
    </PageLayout>
  );
};
