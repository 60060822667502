import { ErrorPage } from 'pages/ErrorPage';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 403:
        return <ErrorPage type={403} />;

      case 404:
        return <ErrorPage type={404} />;
    }
  }

  return <ErrorPage type={500} />;
};
