import { useTranslation } from 'react-i18next';
import { List } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { PageLayout } from 'components/PageLayout';
import { RadioNavigation } from 'components/RadioNavigation';
import { loadingIndicator } from 'components/Loading';
import { Container } from 'config/globalStyles';
import { ListHeader } from './ListHeader';
import { ListItem } from './ListItem';
import { Filters } from './Filters';

import { routes } from 'app/router/routes';
import { useWebTitle } from 'hooks/useWebTitle';
import { usePagination } from 'hooks/usePagination';
import { useApiKeysQuery } from '../queries';

import { AdminPanelIcon } from 'icons';
import { AdminPanelContainer } from '../styles';

export const ApiKeys = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { data: apiKeys, isLoading } = useApiKeysQuery(searchParams);
  const pagination = usePagination(apiKeys?.totalDocs);

  useWebTitle('apiKeys');

  const items = [{ icon: <AdminPanelIcon />, label: t('menu:adminPanel') }];

  const navigationItems = [
    {
      title: t('adminPanel:users.title'),
      to: routes.users(),
    },
    {
      title: t('adminPanel:apiKeys.title'),
      to: routes.apiKeys(),
      active: true,
    },
  ];

  return (
    <PageLayout items={items}>
      <Container size='normal'>
        <RadioNavigation items={navigationItems} />
        <AdminPanelContainer>
          <Filters />
          <ListHeader />
          <List
            dataSource={apiKeys?.docs ?? []}
            loading={{ indicator: loadingIndicator, spinning: isLoading }}
            renderItem={item => <ListItem item={item} />}
            pagination={apiKeys ? pagination : undefined}
          />
        </AdminPanelContainer>
      </Container>
    </PageLayout>
  );
};
