import type { IApiKey } from 'pages/AccountPanel/types';

import { UserRole } from 'config/userRole';

export interface IUserAdminPanel {
  _id: string;
  name: string;
  email: string;
  role: UserRole;
  apiKey: ApiKeyUserStatus;
  limit: number;
  status: UserStatus;
  createdAt: string;
}

export enum ApiKeyUserStatus {
  YES = 'yes',
  NO = 'no',
  PENDING = 'pending',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BLOCKED = 'blocked',
}

export type IApiKeyAdminPanel = Omit<IApiKey, 'user' | 'keyValue'> & {
  _id: string;
  name: string;
  email: string;
};

export type IApiKeyAdminPanelRequest = Omit<IApiKey, 'keyValue'> & {
  _id: string;
};

export type IApiKeyAdminPanelList = Pick<IApiKeyAdminPanel, '_id' | 'name' | 'email' | 'status' | 'createdAt' | 'updatedAt'>;
