import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ReverseProps {
  reverse?: boolean;
}

const colorAccessible = css`
  &:focus {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

export const LinkAccessible = styled(Link)`
  ${colorAccessible}
`;

export const HrefAccessible = styled.a`
  ${colorAccessible}

  svg {
    display: inline-block;
    margin-left: 0.4rem;
    width: 1rem;
    height: 1rem;
  }
`;

export const StyledFooter = styled.footer<ReverseProps>`
  padding: 0;
  display: flex;
  padding: 0 2rem 1rem;
  margin-top: 0.6rem;

  ${props => (props.reverse ? `align-items: end;` : `flex-direction: column;`)}

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    ${props =>
      props.reverse &&
      `flex-direction: column-reverse;
       align-items: flex-start;`}
  }
`;

export const FooterList = styled.ul<ReverseProps>`
  list-style: none;
  font-size: 1.4rem;
  padding: 0;
  margin: 0;

  ${props =>
    props.reverse
      ? `
    order: 2;
    margin-left: 5rem;
  `
      : `
    margin-bottom: 1.5rem;
  `}

  li {
    display: inline-block;

    padding-right: 1rem;
    border-right: 1px solid;
    margin-right: 1rem;
  }

  li:last-child {
    border: none;
  }

  a {
    color: ${props => props.theme.palette.primary.fontColor};
  }

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    ${props => props.reverse && `margin-left: 0; margin-bottom: 1rem`}
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    max-width: 448px;
    font-size: 1.2rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    max-width: 100%;
  }
`;

interface FooterImageContainerProps {
  size: 'normal' | 'small';
}

export const FooterImageContainer = styled.div<FooterImageContainerProps>`
  ${props => props.size === 'small' && `height: 44px`}
`;

export const FooterImage = styled.img`
  max-height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
  }
`;
