import { Filters } from './Filters';

export interface IUsersFilters {
  search?: string;
  role?: string;
  status?: string;
  apiKey?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
}

export class UsersFilters extends Filters {
  constructor(obj: { [k: string]: string }) {
    const filters: { [k: string]: string | number } = {};
    if (obj.search) {
      filters.search = obj.search;
    }
    if (obj.role) {
      filters.role = obj.role;
    }
    if (obj.status) {
      filters.status = obj.status;
    }
    if (obj.apiKey) {
      filters.apiKey = obj.apiKey;
    }
    if (obj.page && Number.isInteger(Number.parseInt(obj.page))) {
      filters.page = +obj.page;
    }
    if (obj.pageSize && Number.isInteger(Number.parseInt(obj.pageSize))) {
      filters.pageSize = +obj.pageSize;
    }

    super(filters);
  }
}
