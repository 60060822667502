import { Link } from 'react-router-dom';

import { StyledLogo } from './styles';

interface LogoProps {
  size?: 'small' | 'large';
}

export const Logo = ({ size = 'small' }: LogoProps): JSX.Element => (
  <Link to='/'>
    <StyledLogo size={size} src='/logo.png' alt='MDB - Medical Data Bank' />
  </Link>
);
