import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { routes } from 'app/router/routes';

import { ApiKeysFilters } from 'models/ApiKeysFilters';
import { UsersFilters } from 'models/UsersFilters';

import { serviceAdminPanel } from './service';

const getUsersQueryKey = (searchParams: URLSearchParams) => {
  const arr = [];

  const page = searchParams.get('page') ?? '1';
  const pageSize = searchParams.get('pageSize') ?? '10';
  const search = searchParams.get('search');
  const role = searchParams.get('role');
  const status = searchParams.get('status');
  const apiKey = searchParams.get('apiKey');

  page && arr.push(page);
  pageSize && arr.push(pageSize);
  search && arr.push(search);
  role && arr.push(role);
  status && arr.push(status);
  apiKey && arr.push(apiKey);

  return arr;
};

export const useUsersQuery = (searchParams: URLSearchParams) =>
  useQuery({
    queryKey: ['adminPanel', 'users', 'list', ...getUsersQueryKey(searchParams)],
    queryFn: () => serviceAdminPanel.getUsers(new UsersFilters(Object.fromEntries(searchParams))),
    placeholderData: keepPreviousData,
  });

export const useGrantAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => serviceAdminPanel.grantAdmin(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'users', 'list'],
      });
    },
  });
};

export const useRevokeAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => serviceAdminPanel.revokeAdmin(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'users', 'list'],
      });
    },
  });
};

export const useActivateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => serviceAdminPanel.activateUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'users', 'list'],
      });
    },
  });
};

export const useBlockUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => serviceAdminPanel.blockUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'users', 'list'],
      });
    },
  });
};

export const useUnblockUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => serviceAdminPanel.unblockUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'users', 'list'],
      });
    },
  });
};

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => serviceAdminPanel.deleteUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'users', 'list'],
      });
    },
  });
};

const getApiKeysQueryKey = (searchParams: URLSearchParams) => {
  const arr = [];

  const page = searchParams.get('page') ?? '1';
  const pageSize = searchParams.get('pageSize') ?? '10';
  const search = searchParams.get('search');
  const status = searchParams.get('status');

  page && arr.push(page);
  pageSize && arr.push(pageSize);
  search && arr.push(search);
  status && arr.push(status);

  return arr;
};

export const useApiKeysQuery = (searchParams: URLSearchParams) =>
  useQuery({
    queryKey: ['adminPanel', 'apiKeys', 'list', ...getApiKeysQueryKey(searchParams)],
    queryFn: () => serviceAdminPanel.getApiKeys(new ApiKeysFilters(Object.fromEntries(searchParams))),
    placeholderData: keepPreviousData,
  });

export const useApiKeyQuery = (apiKeyId: string) =>
  useQuery({
    queryKey: ['adminPanel', 'apiKeys', 'details', apiKeyId],
    queryFn: () => serviceAdminPanel.getApiKey(apiKeyId),
    placeholderData: keepPreviousData,
  });

export const useGenerateApiKeyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (apiKeyId: string) => serviceAdminPanel.generateApiKey(apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'apiKeys'],
      });
    },
  });
};

export const useDeclineApiKeyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (apiKeyId: string) => serviceAdminPanel.declineApiKey(apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'apiKeys'],
      });
    },
  });
};

export const useBlockApiKeyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (apiKeyId: string) => serviceAdminPanel.blockApiKey(apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'apiKeys'],
      });
    },
  });
};

export const useUnlockApiKeyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (apiKeyId: string) => serviceAdminPanel.unblockApiKey(apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'apiKeys'],
      });
    },
  });
};

export const useDeleteApiKeyMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (apiKeyId: string) => serviceAdminPanel.deleteApiKey(apiKeyId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['adminPanel', 'apiKeys'],
      });
      navigate(routes.apiKeys());
    },
  });
};
