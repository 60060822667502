import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import type { IHeaderItem } from 'components/Header';

import { MainContent, PageContainer } from './styles';

interface PageLayoutProps {
  children: JSX.Element;
  items: IHeaderItem[];
  hideUserDropdown?: boolean;
}

export const PageLayout = ({ children, items, hideUserDropdown }: PageLayoutProps): JSX.Element => (
  <PageContainer>
    <Header items={items} hideUserDropdown={hideUserDropdown} />
    <MainContent>{children}</MainContent>
    <Footer reverse ueImgSize='small' />
  </PageContainer>
);
