import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { commonFocusCss } from 'config/globalStyles';

export const LinkAccessible = styled(Link)`
  &:focus {
    ${commonFocusCss}
  }
`;

export const ActionButton = styled(Button)`
  text-transform: uppercase;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.fontColor};
  border-radius: ${({ theme }) => theme.borderRadius};

  &.ant-btn-primary {
    background-color: ${props => props.theme.palette.secondary.main};
    border-color: ${props => props.theme.palette.secondary.main};
  }

  &.ant-btn-text:hover {
    background-color: transparent;
    border-color: ${props => props.theme.palette.secondary.main};
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;
