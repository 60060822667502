import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { IContactForm } from 'models/Contact';

import { Input } from 'components/Form/Input';
import { Form } from 'components/Form/Form';
import { useValidation } from 'hooks/useValidation';
import { Select } from 'components/Form/Select';
import { Container } from 'config/globalStyles';

import { StyledButton, Title, FormContainer } from './styles';
import { useContactMutation } from './queries';

interface ContactFormProps {
  handleContactFormCompleted: () => void;
}

export const ContactForm = ({ handleContactFormCompleted }: ContactFormProps): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      text: '',
      subject: '',
    },
  });
  const { t } = useTranslation();
  const { validate } = useValidation();
  const { mutate: sendMessage, isPending } = useContactMutation(handleContactFormCompleted);

  const onSubmit: SubmitHandler<IContactForm> = (data: IContactForm) => {
    sendMessage(data);
  };

  return (
    <Container>
      <Title>{t('contact:title')}</Title>
      <FormContainer>
        <Form<IContactForm> onSubmit={handleSubmit(onSubmit)}>
          <Input<IContactForm>
            name='email'
            label={t('contact:email')}
            control={control}
            errors={errors}
            rules={validate('email')}
            restProps={{ type: 'email' }}
          />

          <Select<IContactForm>
            name='subject'
            label={t('contact:subjectLabel')}
            control={control}
            errors={errors}
            rules={validate('required')}
            options={[
              { label: t('contact:subjects.error'), value: 'error' },
              { label: t('contact:subjects.feedback'), value: 'feedback' },
              { label: t('contact:subjects.help'), value: 'help' },
              { label: t('contact:subjects.other'), value: 'other' },
            ]}
          />

          <Input.TextArea<IContactForm>
            name='text'
            label={t('contact:text')}
            control={control}
            errors={errors}
            rules={{ ...validate('required'), ...validate('minLength', 3) }}
          />

          <StyledButton type='primary' htmlType='submit' loading={isPending}>
            {t('contact:send')}
          </StyledButton>
        </Form>
      </FormContainer>
    </Container>
  );
};
