import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkAccessible = styled(Link)`
  &:focus {
    color: ${props => props.theme.palette.secondary.main};
  }
`;

export const HeaderStyled = styled.header`
  display: flex;
  background-color: ${props => props.theme.palette.common.white};
  padding: 1.5rem 3rem;
  align-items: center;
  width: 100%;
`;

interface HeaderItemsProps {
  userPanel: boolean;
}

export const HeaderItems = styled.ul<HeaderItemsProps>`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 2rem;
  max-width: ${({ userPanel }) =>
    userPanel ? 'calc(100% - 19.4rem - 20rem)' : 'calc(100% - 19.4rem - 11.8rem)'}; // logo - button
  height: 32px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    display: none;
  }
`;

export const HeaderItemStyled = styled.li`
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  min-width: 0;
  white-space: nowrap;
  border-right: 1px solid;
  padding: 0 1rem;

  :first-child {
    padding-left: 0;
  }

  :last-child {
    min-width: 0;
    border: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
    :first-child {
      display: flex;
    }
  }

  svg {
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 1rem;
  }
`;

export const HeaderItemLabel = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3rem;
  font-weight: 600;
  width: 100%;

  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LoginButton = styled(Link)`
  text-transform: uppercase;
  padding: 0 16px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.fontColor};
  background-color: ${props => props.theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: fit-content;
  white-space: nowrap;

  :hover,
  :focus {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const UserDropdownContainer = styled.div`
  margin-left: auto;
  max-width: 20rem;
  user-select: none;

  svg {
    height: 1.8rem;
    width: 1.8rem;
  }
`;

export const UserDropdownContent = styled.a`
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    margin-left: 2rem;
    gap: 8px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  gap: 0px;
  width: calc(100% - 3rem);

  * {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.4;
  }
`;

export const UserName = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const UserEmail = styled.span`
  font-size: 1rem;
`;
