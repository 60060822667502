import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Titles =
  | 'home'
  | 'contact'
  | 'apiKeyInformation'
  | 'images'
  | 'details'
  | 'forbidden'
  | 'notFound'
  | 'internalError'
  | 'login'
  | 'register'
  | 'activateAccount'
  | 'basicInformation'
  | 'passwordChange'
  | 'apiKey'
  | 'users'
  | 'apiKeys'
  | 'apiKeysDetails';

export const useWebTitle = (type: Titles, extra?: string) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(`webTitles:${type}`, extra ? { extra: extra } : {});
  }, [t, type, extra]);
};
