import { Logo } from 'components/Logo';
import { HeaderItem } from './HeaderItem';
import { UserDropdown } from './UserDropdown';

import { useWhoAmIQuery } from 'pages/Auth/queries';

import { HeaderItems, HeaderStyled } from './styles';

export interface IHeaderItem {
  icon?: JSX.Element;
  label: string;
  hideLabel?: boolean;
  link?: string;
}

interface HeaderProps {
  items: IHeaderItem[];
  hideUserDropdown?: boolean;
}

export const Header = ({ items, hideUserDropdown }: HeaderProps): JSX.Element => {
  const { data: user, isLoading, isFetching, error } = useWhoAmIQuery();

  return (
    <HeaderStyled>
      <Logo />
      <HeaderItems userPanel={!!user && !isLoading}>
        {items.map(item => (
          <HeaderItem key={item.label} item={item} />
        ))}
      </HeaderItems>
      {!hideUserDropdown && <UserDropdown user={user} loading={isLoading || isFetching} error={error} />}
    </HeaderStyled>
  );
};
