import { useMutation } from '@tanstack/react-query';

import type { ErrorResponse } from 'helpers/http';

import { useGAEventsTracker } from 'hooks/useGAEventsTracker';
import { serviceImageDownload } from './service';

export const useImageDownloadMutation = () => {
  const metric = useGAEventsTracker();

  return useMutation<void, ErrorResponse | null, string, unknown>({
    mutationFn: (imageId: string) => serviceImageDownload.getImage(imageId),
    onSuccess: (_, imageId) => {
      metric('Download', 'image download', imageId);
    },
  });
};
