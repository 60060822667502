import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { routes } from 'app/router/routes';

import { Image } from 'models/Image';
import { ListItemHeader } from './ListItemHeader';
import { ListItemPanel } from './ListItemPanel';

import { LinkAccessible, ListItemStyled } from '../styles';

export interface ItemCollapseProps {
  item: Image;
  collapsed: boolean;
  setCollapsed?: (collapsed: boolean) => void;
}

interface ListItemProps {
  item: Image;
}

export const ListItem = ({ item }: ListItemProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();

  return (
    <li>
      <LinkAccessible to={routes.image(item.id)} state={{ from: location.pathname.concat(location.search) }}>
        <ListItemStyled collapsed={collapsed}>
          <ListItemHeader collapsed={collapsed} setCollapsed={setCollapsed} item={item} />
          <ListItemPanel item={item} collapsed={collapsed} />
        </ListItemStyled>
      </LinkAccessible>
    </li>
  );
};
