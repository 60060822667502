import { useContext, useEffect } from 'react';
import { ScaleLine as ScaleLineOl } from 'ol/control';

import { ImageMapContext } from '../ImageMapContext';

export const ScaleLine = () => {
  const { map } = useContext(ImageMapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    const scaleLine = new ScaleLineOl({
      units: 'metric',
    });

    map.addControl(scaleLine);

    return () => {
      if (map) {
        map.removeControl(scaleLine);
      }
    };
  }, [map]);

  return null;
};
