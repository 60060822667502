import { Input as InputAntd } from 'antd';
import { Controller } from 'react-hook-form';
import get from 'lodash.get';

import type { GroupProps as GroupAntdProps } from 'antd/lib/input';
import type { FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';

import { StyledFormItem } from '../styles';

interface GroupProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  errors: FieldErrors<T>;
  restProps?: GroupAntdProps;
  optional?: boolean;
}

export const Group = <T extends FieldValues>({
  name,
  label,
  errors,
  control,
  rules,
  restProps,
  optional,
}: GroupProps<T>): JSX.Element => (
  <StyledFormItem
    label={label}
    required={optional ? false : true}
    validateStatus={get(errors, name) ? 'error' : ''}
    help={<>{get(errors, name)?.message}</>}
  >
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => <InputAntd.Group {...field} {...restProps} aria-required={optional ? false : true} />}
    />
  </StyledFormItem>
);
