import { notification } from 'antd';

import { translateErrorCode } from './translation';

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

const createNotification = (type: NotificationType, message: string, description: string | undefined = undefined) => {
  notification[type]({
    message,
    description,
  });
};

export const errorNotification = (errorCodes: number[]) => {
  errorCodes.forEach(code => {
    const translatedCode = translateErrorCode(code);
    translatedCode && createNotification(NotificationType.ERROR, translatedCode);
  });
};

export const errorNotificationWithoutErrorCode = (message: string, description: string | undefined = undefined) => {
  createNotification(NotificationType.ERROR, message, description);
};

export const successNotification = (message: string, description: string | undefined = undefined) => {
  createNotification(NotificationType.SUCCESS, message, description);
};

export default createNotification;
