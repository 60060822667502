import { useTranslation } from 'react-i18next';

import { ApiKeyDescription, ApiKeyTitle } from '../style';

export const WaitingApiKey = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <ApiKeyTitle>{t('accountPanel:apiKey.states.apiKeyWaitingTitle')}</ApiKeyTitle>
      <ApiKeyDescription>{t('accountPanel:apiKey.states.apiKeyWaitingDescription')}</ApiKeyDescription>
    </>
  );
};
