import styled from 'styled-components';

export const PageContainer = styled.div`
  overflow: hidden;
  /* padding-bottom: 3rem; */
  min-height: 100vh;
`;

export const PageLargeContainer = styled.div`
  min-height: 100vh;
  max-width: 100vw;
  padding: 0 4.8rem;
  padding-bottom: 2rem;
`;

export const MainContent = styled.div`
  min-height: calc(100vh - 62px - 54px); // max - header - footer

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    min-height: calc(100vh - 62px - 86px); // max - header - footer
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    min-height: calc(100vh - 62px - 102px); // max - header - footer
  }

  @media (max-width: 336px) {
    min-height: calc(100vh - 62px - 121px); // max - header - footer
  }
`;
