import { ReactNode, useEffect } from 'react';
import { ConfigProvider as ConfigProviderAntd } from 'antd';
import enUS from 'antd/es/locale/en_US';
import plPL from 'antd/es/locale/pl_PL';
import { getDefaultLanguage } from 'config/i18n';
import { Language } from 'config/lang';

interface ConfigProviderAntdProps {
  children: ReactNode;
}

export const ConfigProvider = ({ children }: ConfigProviderAntdProps) => {
  const getLanguage = () => {
    const lang = getDefaultLanguage();

    switch (lang) {
      case Language.PL:
        return plPL;

      case Language.EN:
        return enUS;

      default:
        return plPL;
    }
  };

  const vhResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    vhResize();
  }, []);

  window.addEventListener('resize', () => {
    vhResize();
  });

  return <ConfigProviderAntd locale={getLanguage()}>{children}</ConfigProviderAntd>;
};
