import styled from 'styled-components';

export const LoginActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;
