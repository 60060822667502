import { Button } from 'antd';
import styled from 'styled-components';

import { commonFocusCss } from 'config/globalStyles';

interface DetailsContainerProps {
  visibleConnectedImages: boolean;
}

interface ImageDetailsContainerProps extends DetailsContainerProps {
  visibleContainer: boolean;
  visibleMap: boolean;
}

export const ImageDetailsButtonStyled = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  border: 0;
  border-radius: 7px 0px 0px 7px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: ${props => props.theme.palette.secondary.main};

  &:focus {
    background-color: ${props => props.theme.palette.secondary.main};
    opacity: 80%;
    ${commonFocusCss};
  }

  svg {
    margin-right: 1rem;
    height: 1rem;
  }
`;

export const ImageDetailsContainerStyled = styled.div<ImageDetailsContainerProps>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: ${props => (props.visibleMap ? (props.visibleConnectedImages ? '14rem' : '3rem') : 0)};
  width: 30%;
  border-radius: 12px 0px 0px 12px;
  background-color: ${props => props.theme.palette.primary.fontColor};
  color: ${props => props.theme.palette.common.white};
  padding: 4rem 0;
  transform: ${props => (props.visibleContainer ? `translate(0,0)` : `translate(100%,-100%)`)};
  transition: bottom 300ms ease, transform 300ms ease;
  z-index: 1;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    width: 50%;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
  }
`;

export const ImageContainerTitle = styled.h1`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 4rem;
  padding: 0 4rem;
  width: 100%;
  color: ${props => props.theme.palette.common.white};
`;

export const ImageContainerSectionHeader = styled.h2`
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.6rem;
  margin-bottom: 1.5rem;
  width: 100%;
  color: ${props => props.theme.palette.common.white};

  svg {
    font-size: 1.6rem;
    vertical-align: middle;
    margin-right: 1.2rem;
  }
`;

export const ImageContainerSeparator = styled.hr`
  width: 100%;
  margin: 0.6rem 0 1.6rem;
`;

export const DetailsContainer = styled.dl<DetailsContainerProps>`
  overflow-y: scroll;
  padding: 0 4rem;
  height: calc(100% - 4rem); // 100% -> ImageDetailsContainerStyled, 4rem -> padding ImageDetailsContainerStyled
`;

export const DetailsTitle = styled.dt`
  font-weight: 300;
  font-size: 1.2rem;
  width: 50%;
  margin-bottom: 1rem;
  display: inline-block;
  vertical-align: top;
`;

export const DetailsValue = styled.dd`
  font-weight: 500;
  font-size: 1.2rem;
  width: 50%;
  margin-bottom: 1rem;
  display: inline-block;
  vertical-align: top;
`;
