import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Image } from 'models/Image';

import { useGAEventsTracker } from 'hooks/useGAEventsTracker';
import { serviceImage } from './service';

export const useImageQuery = () => {
  const metric = useGAEventsTracker();
  const { id } = useParams();

  id && metric('Visiting', 'visiting the image page', id);

  return useQuery({
    queryKey: ['images', 'details', id],
    queryFn: () => serviceImage.getImage(id ?? ''),
    select: data => ({ image: new Image(data.image), connectedImages: data.connectedImages }),
    enabled: !!id,
  });
};
