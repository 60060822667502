import { useContext, useEffect } from 'react';
import { View as ViewOl } from 'ol';
import { Tile as TileLayerOl } from 'ol/layer';
import { Projection as ProjectionOl } from 'ol/proj';
import { Zoomify } from 'ol/source';

import type { ITileLayerInfo } from '../types';

import { ImageMapContext } from '../ImageMapContext';

interface TileLayerProps {
  tilesPath: string;
  pixelSpacing: number;
  tilesUrl: string;
  tileLayerInfo?: ITileLayerInfo;
}

export const TileLayer = ({ tilesPath, pixelSpacing, tilesUrl, tileLayerInfo }: TileLayerProps) => {
  const { map } = useContext(ImageMapContext);

  useEffect(() => {
    if (!map || !tileLayerInfo) {
      return;
    }

    const source = new Zoomify({
      url: tilesUrl,
      size: [tileLayerInfo.width, tileLayerInfo.height],
      crossOrigin: 'anonymous',
      zDirection: -1,
    });

    const tileLayer = new TileLayerOl({
      source: source,
    });

    const extent = source.getTileGrid()?.getExtent();

    // need to get 2 more zoom levels then standard resolutions provide
    const resolutions = tileLayer.getSource()?.getTileGrid()?.getResolutions()
      ? tileLayer.getSource()?.getTileGrid()?.getResolutions().concat([0.5, 0.25])
      : undefined;

    map.addLayer(tileLayer);
    map.setView(
      new ViewOl({
        projection: new ProjectionOl({
          code: 'DICOM',
          global: true,
          getPointResolution: pixelRes => (pixelRes * pixelSpacing) / 10 ** 3,
        }),
        resolutions: resolutions,
        extent: extent,
        constrainOnlyCenter: true,
        minZoom: 1,
      }),
    );

    extent && map.getView().fit(extent);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map, tilesPath, tileLayerInfo, tilesUrl, pixelSpacing]);

  return null;
};
