import { useTranslation } from 'react-i18next';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { routes } from 'app/router/routes';
import { useWebTitle } from 'hooks/useWebTitle';

import { ErrorPageContainer } from './style';

interface ErrorPageProps {
  type: 403 | 404 | 500;
}

export const ErrorPage = ({ type }: ErrorPageProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useWebTitle(type === 403 ? 'forbidden' : type === 404 ? 'notFound' : 'internalError');

  return (
    <ErrorPageContainer>
      <Result
        status={type}
        title={type}
        subTitle={t(`errorPage:${type}.description`)}
        extra={
          <Button type='primary' onClick={() => navigate(routes.base())}>
            {t(`errorPage:backToHomePage`)}
          </Button>
        }
      />
    </ErrorPageContainer>
  );
};
