import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { useForm } from 'react-hook-form';

import { IImageFilters, Supplier } from 'models/ImageFilters';

import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';

import { FiltersLabel, FiltersContainer, LabelContainer } from '../styles';

export const Filters = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IImageFilters>({
    defaultValues: {},
  });

  useEffect(() => {
    const supplierParam = searchParams.get('supplier') ?? '';

    setValue('tissue', searchParams.get('tissue') ?? undefined);
    setValue('diagnosis', searchParams.get('diagnosis') ?? undefined);
    setValue('supplier', supplierParam in Supplier ? supplierParam : undefined);
  }, [searchParams, setValue]);

  const onDiagnosisChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.diagnosis;
    }
    setSearchParams({ ...oldValues, diagnosis: newValue, page: '1' });
  };

  const onTissueChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.tissue;
    }
    setSearchParams({ ...oldValues, tissue: newValue, page: '1' });
  };

  const onSupplierChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.supplier;
      setSearchParams({ ...oldValues, page: '1' });
      return;
    }
    setSearchParams({ ...oldValues, supplier: newValue, page: '1' });
  };

  const supplierOptions = Object.entries(Supplier).map(([key, value]) => ({
    label: t(`images:supplier.${key}`, { defaultValue: '' }),
    value,
  }));

  return (
    <Form name='filterForm'>
      <Row gutter={16}>
        <LabelContainer>
          <FiltersLabel>{t('images:filters')}:</FiltersLabel>
        </LabelContainer>
        <FiltersContainer>
          <Col>
            <Input<IImageFilters>
              name='diagnosis'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: event => onDiagnosisChange(event.target.value),
                placeholder: t('images:diagnosis'),
                allowClear: true,
              }}
            />
          </Col>
          <Col>
            <Input<IImageFilters>
              name='tissue'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: event => onTissueChange(event.target.value),
                placeholder: t('images:tissue'),
                allowClear: true,
              }}
            />
          </Col>
          <Col>
            <Select<IImageFilters>
              name='supplier'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: value => onSupplierChange(value),
                placeholder: t('images:supplier.title'),
                allowClear: true,
                showArrow: watch('supplier') ? false : true,
              }}
              options={supplierOptions}
            />
          </Col>
        </FiltersContainer>
      </Row>
    </Form>
  );
};
