import { Typography } from 'antd';
import { Image } from 'models/Image';
import { useTranslation } from 'react-i18next';
import { ListItemPanelStyled } from '../styles';
import { Collapse } from 'react-collapse';

const { Paragraph } = Typography;

export interface ListItemPanelProps {
  item: Image;
  collapsed: boolean;
}

export const ListItemPanel = ({ item, collapsed }: ListItemPanelProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Collapse isOpened={!collapsed}>
      <ListItemPanelStyled className='panel'>
        <h3>{t('images:description')}</h3>
        <Paragraph>{item.diagnosis}</Paragraph>
      </ListItemPanelStyled>
    </Collapse>
  );
};
