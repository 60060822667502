import type { IImageConnected } from 'models/Image';

import { Image } from 'models/Image';

import { JpgBase } from './MapBase/JpgBase';
import { TileBase } from './MapBase/TileBase';

interface ImageMapProps {
  image: Image;
  connectedImages: IImageConnected[];
}

export const ImageMap = ({ image, connectedImages }: ImageMapProps): JSX.Element => {
  if (image.jpgBasePath) {
    return <JpgBase image={image} connectedImages={connectedImages} />;
  }

  return <TileBase image={image} connectedImages={connectedImages} />;
};
