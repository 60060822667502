import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { useForm } from 'react-hook-form';

import { IApiKeysFilters } from 'models/ApiKeysFilters';

import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';

import { ApiKeyStatus } from 'pages/AccountPanel/types';

import { FiltersContainer } from '../styles';

export const Filters = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IApiKeysFilters>({
    defaultValues: {},
  });

  useEffect(() => {
    const statusParam = searchParams.get('status') ?? '';

    setValue('search', searchParams.get('search') ?? undefined);
    setValue('status', Object.values(ApiKeyStatus).includes(statusParam as ApiKeyStatus) ? statusParam : undefined);
  }, [searchParams, setValue]);

  const onSearchChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.search;
      setSearchParams({ ...oldValues, page: '1' });
      return;
    }
    setSearchParams({ ...oldValues, search: newValue, page: '1' });
  };

  const onStatusChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.status;
      setSearchParams({ ...oldValues, page: '1' });
      return;
    }
    setSearchParams({ ...oldValues, status: newValue, page: '1' });
  };

  const statusOptions = Object.values(ApiKeyStatus).map(value => ({
    label: t(`adminPanel:apiKeys.apiKeyStatus.${value}`, { defaultValue: '' }),
    value,
  }));

  return (
    <Form<IApiKeysFilters>>
      <Row gutter={16}>
        <FiltersContainer>
          <Col>
            <Input
              name='search'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: event => onSearchChange(event.target.value),
                placeholder: t('adminPanel:apiKeys.nameOrEmail'),
                allowClear: true,
              }}
            />
          </Col>
          <Col>
            <Select
              name='status'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: value => onStatusChange(value),
                placeholder: t('adminPanel:apiKeys.status'),
                allowClear: true,
                showArrow: watch('status') ? false : true,
              }}
              options={statusOptions}
            />
          </Col>
        </FiltersContainer>
      </Row>
    </Form>
  );
};
