import React from 'react';
import styled from 'styled-components';

interface ErrorPageContainerProps {
  children: React.ReactNode;
}

export const ErrorPageContainer = styled.div<ErrorPageContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.palette.common.white};
`;
