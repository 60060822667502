export enum routeParam {
  id = 'id',
  redirectCounter = 'redirectCounter',
}

export const subRoutes = {
  base: '/',
  auth: 'auth',
  noAccess: 'no-access',
  notFound: '*',

  login: 'login',
  register: 'register',
  activateAccount: 'activate-account',

  images: 'images',
  contact: 'contact',
  apiKeyInformation: 'api-key-information',

  account: 'account',

  basicInformation: 'basic-information',
  passwordChange: 'password-change',
  apiKey: 'api-key',

  adminPanel: 'admin',

  users: 'users',
  apiKeys: 'api-keys',
};

export const routes = {
  base: () => subRoutes.base,
  auth: () => `${routes.base()}${subRoutes.auth}`,
  noAccess: () => `${routes.base()}${subRoutes.noAccess}`,

  login: () => `${routes.auth()}/${subRoutes.login}`,
  register: () => `${routes.auth()}/${subRoutes.register}`,
  activateAccount: () => `${routes.auth()}/${subRoutes.activateAccount}`,

  images: () => `${routes.base()}${subRoutes.images}`,
  image: (id: string) => `${routes.images()}/${id}`,
  apiKeyInformation: () => `${routes.base()}${subRoutes.apiKeyInformation}`,
  contact: () => `${routes.base()}${subRoutes.contact}`,

  account: () => `${routes.base()}${subRoutes.account}`,

  basicInformation: () => `${routes.account()}/${subRoutes.basicInformation}`,
  passwordChange: () => `${routes.account()}/${subRoutes.passwordChange}`,
  apiKey: () => `${routes.account()}/${subRoutes.apiKey}`,

  adminPanel: () => `${routes.base()}${subRoutes.adminPanel}`,

  users: () => `${routes.adminPanel()}/${subRoutes.users}`,
  apiKeys: () => `${routes.adminPanel()}/${subRoutes.apiKeys}`,
  apiKeyDetails: (id: string) => `${routes.apiKeys()}/${id}`,
} as const;
