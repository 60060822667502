import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'antd';

import type { SubmitHandler } from 'react-hook-form';
import type { IUserLogin } from 'models/Auth';

import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { PageLayout } from 'components/PageLayout';
import { RadioNavigation } from 'components/RadioNavigation';
import { Container } from 'config/globalStyles';

import { useValidation } from 'hooks/useValidation';
import { useWebTitle } from 'hooks/useWebTitle';
import { useLoginMutation } from '../queries';
import { routes } from 'app/router/routes';

import { LoginActionContainer } from './style';
import { ActionButton } from '../style';
import { AccountIcon } from 'icons';

export const Login = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IUserLogin>({ defaultValues: { email: '', password: '' } });
  const { t } = useTranslation();
  const { validate } = useValidation();
  const { mutate: login, isPending } = useLoginMutation();
  useWebTitle('login');

  const onSubmit: SubmitHandler<IUserLogin> = data => {
    login(data);
  };

  const items = [{ icon: <AccountIcon />, label: t('menu:userAccount') }];

  const navigationItems = [
    {
      title: t('auth:loginTitle'),
      to: routes.login(),
      active: true,
    },
    {
      title: t('auth:registerTitle'),
      to: routes.register(),
    },
  ];

  return (
    <PageLayout items={items} hideUserDropdown>
      <Container size='normal'>
        <RadioNavigation items={navigationItems} />
        <Form<IUserLogin> onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12} md={18} xs={24}>
              <Input
                name='email'
                label={t('auth:email')}
                control={control}
                errors={errors}
                rules={validate('email')}
                restProps={{ type: 'email', autoFocus: true }}
              />
              <Input.Password
                name='password'
                label={t('auth:password')}
                control={control}
                errors={errors}
                rules={validate('required')}
              />

              <LoginActionContainer>
                <ActionButton type='text'>{t('auth:resetPassword')}</ActionButton>
                <ActionButton type='primary' htmlType='submit' loading={isPending}>
                  {t('auth:login')}
                </ActionButton>
              </LoginActionContainer>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageLayout>
  );
};
