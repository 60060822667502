import { Button, Col, Row, Slider, Select } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Swiper } from 'swiper/react';

import type { IOverviewMapInfo } from './ImageMap/types';

import { commonFocusCss } from 'config/globalStyles';

interface VisibleConnectedImagesProps {
  visibleConnectedImages: boolean;
}

export const MapContainer = styled.div`
  height: calc(100% - 3rem);
  width: 100%;
`;

export const LinkAccessible = styled(Link)`
  &:focus {
    img {
      ${commonFocusCss}
    }
  }
`;

const ControlContainer = styled.div`
  height: 3rem;
`;

interface StyledMapProps {
  overviewMapInfo?: IOverviewMapInfo;
}

export const StyledMap = styled.div<StyledMapProps>`
  height: calc(100vh - 6.6rem); /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100 - 6.6rem);
  font-weight: 600;
  overflow: hidden;
  position: relative;

  .image-viewer {
    height: calc(100% - 3rem); // 100% - map control panel
  }

  .rotate-control {
    left: auto;
    right: 0.5em;
    top: 2.5em;
  }

  .ol-overviewmap {
    top: 3.5rem;
    left: 3.5rem;
    bottom: auto;
    background-color: ${props => props.theme.palette.common.white};
  }

  .ol-overviewmap .ol-overviewmap-map {
    margin: 0;
    height: ${props => props.overviewMapInfo?.height};
    width: ${props => props.overviewMapInfo?.width};
  }

  .ol-overviewmap button {
    color: ${props => props.theme.palette.common.white};
    background-color: ${props => props.theme.palette.primary.fontColor};
    font-size: 2rem;
    cursor: pointer;
    font-weight: normal;
  }

  .ol-overviewmap:not(.ol-collapsed) button {
    color: ${props => props.theme.palette.primary.fontColor};
    background: transparent;
    cursor: pointer;

    font-size: 2.4rem;
    font-weight: bold;

    height: 2rem;
    width: 2rem;
    top: 0.2rem;
    right: 0.2rem;
    left: auto;
    bottom: auto;
  }

  .ol-overviewmap-box {
    border: 3px dashed ${props => props.theme.palette.secondary.main};
  }

  .ol-scale-line {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 0;
  }

  .ol-scale-line-inner {
    border-color: ${props => props.theme.palette.primary.fontColor};
    color: ${props => props.theme.palette.primary.fontColor};
  }
`;

export const Separator = styled.div`
  width: 1px;
  background-color: ${props => props.theme.palette.primary.fontColor};
`;

export const ControlsCol = styled(Col)`
  display: flex;
`;

export const ZoomView = styled.div`
  width: 7rem;
  text-align: center;
`;

export const ControlButton = styled(Button)`
  border: 0;
  border-radius: 0;
  background-color: transparent;
  height: 3rem;
  font-weight: 600;
  padding: 0;

  &:hover {
    background-color: ${props => props.theme.palette.secondary.main};
  }

  &:focus {
    ${commonFocusCss}
  }

  span {
    padding: 0 1rem;
  }

  svg {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0.9rem;
  }
`;

export const FullScreenButton = styled(ControlButton)`
  svg {
    height: 2.2rem;
    width: 2.4rem;
    margin: 0.4rem 0.9rem;
  }
`;

export const ControlContainerZoom = styled(ControlContainer)``;

export const ControlContainerButtons = styled(ControlContainer)`
  display: flex;
`;

export const ContainerArrows = styled(ControlContainer)`
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const SliderContainer = styled(ControlContainer)`
  display: flex;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    display: none;
  }
`;

export const ImageControlContainer = styled(ControlContainer)`
  display: flex;
`;

export const StyledSlider = styled(Slider)`
  width: 150px;

  .ant-slider-rail,
  .ant-slider-track {
    height: 1px;
    background-color: ${props => props.theme.palette.primary.fontColor};
  }

  .ant-slider-handle {
    width: 0.8rem;
    height: 0.8rem;
    border: 1px solid ${props => props.theme.palette.primary.fontColor};
    margin-top: -0.3rem;
  }
`;

export const ImageControlsStyled = styled.div<VisibleConnectedImagesProps>`
  line-height: 3rem;
  position: absolute;
  width: 100%;
  background-color: ${props => props.theme.palette.grayscale.main};
  transition: top 300ms ease;
  top: ${props => (props.visibleConnectedImages ? 'calc(100% - 14rem)' : 'calc(100% - 3rem)')};
`;

export const ConnectedImagesLabel = styled.span`
  text-transform: uppercase;
  font-size: 1.1rem;
  height: 3rem;
  line-height: 3rem;
  vertical-align: top;
`;

export const ConnectedImagesButton = styled(ControlButton)`
  font-size: 1.2rem;
  padding-right: 0;

  ${props =>
    props.disabled &&
    `svg {
      opacity: 50%;
    }`}
`;

export const ConnectedImagesContainer = styled(Row)`
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
`;

export const ConnectedImagePreview = styled.img`
  height: 8rem;
  border: 1px solid ${props => props.theme.palette.primary.fontColor};
  border-radius: 7px;

  &:hover {
    border-color: ${props => props.theme.palette.secondary.main};
  }
`;

export const StyledSwiper = styled(Swiper)`
  width: 80%;
  overflow: visible;

  .swiper-wrapper {
    overflow: visible;
  }
  div {
    display: flex;
    justify-content: center;
  }

  .swiper-button-prev {
    left: -30px;
    color: ${props => props.theme.palette.common.black};

    :after {
      font-size: 1.4rem;
    }
  }

  .swiper-button-next {
    right: -30px;
    color: ${props => props.theme.palette.common.black};

    :after {
      font-size: 1.4rem;
    }
  }
`;

export const ContainerZoom = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const ContainerZoomMobile = styled.div`
  display: none;
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: flex;
  }
`;

export const StyledSelectMobile = styled(Select)`
  font-size: 2rem;

  .ant-select-selector {
    border: 0 !important;
    background-color: ${props => props.theme.palette.grayscale.main} !important;
  }
`;
