import { Filters } from './Filters';

export enum Supplier {
  PL = 'PL',
  ICZMP = 'ICZMP',
}

export interface IImageFilters {
  diagnosis?: string;
  tissue?: string;
  supplier?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
}

export class ImageFilters extends Filters {
  constructor(obj: { [k: string]: string }) {
    const filters: { [k: string]: string | number } = {};
    if (obj.age && Number.isInteger(Number.parseInt(obj.age))) {
      filters.age = +obj.age;
    }
    if (obj.page && Number.isInteger(Number.parseInt(obj.page))) {
      filters.page = +obj.page;
    }
    if (obj.pageSize && Number.isInteger(Number.parseInt(obj.pageSize))) {
      filters.pageSize = +obj.pageSize;
    }
    if (obj.diagnosis) {
      filters.diagnosis = obj.diagnosis;
    }
    if (obj.tissue) {
      filters.tissue = obj.tissue;
    }
    if (obj.supplier) {
      filters.supplier = obj.supplier;
    }

    super(filters);
  }
}
