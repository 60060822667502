import { PaginationConfig } from 'antd/lib/pagination';
import { useSearchParams } from 'react-router-dom';

import { ITEMS_PER_PAGE } from 'config/settings';

export const usePagination = (total?: number): PaginationConfig => {
  const [searchParams, setSearchParams] = useSearchParams();

  let page = 1;
  const paramsPage = searchParams.get('page');
  if (paramsPage && Number.isInteger(Number.parseInt(paramsPage))) {
    page = Number.parseInt(paramsPage);
  }

  let pageSize = ITEMS_PER_PAGE;
  const paramsPageSize = searchParams.get('pageSize');
  if (paramsPageSize && Number.isInteger(Number.parseInt(paramsPageSize))) {
    pageSize = Number.parseInt(paramsPageSize);
  }

  const handlePageChange = (newPage: number, newPageSize: number) => {
    if (pageSize !== newPageSize) {
      searchParams.set('pageSize', newPageSize.toString());
      searchParams.set('page', '1');
    } else {
      searchParams.set('page', newPage.toString());
    }
    setSearchParams(searchParams);
  };

  return {
    showSizeChanger: true,
    current: page,
    onChange: handlePageChange,
    position: 'bottom',
    hideOnSinglePage: false,
    total: total,
    pageSize: pageSize,
  };
};
