import type { IHeaderItem } from '.';

import { SrOnly } from 'components/SrOnly/style';

import { ItemTextContainer, HeaderItemStyled, HeaderItemLabel, LinkAccessible } from './styles';

interface HeaderItemProps {
  item: IHeaderItem;
}

export const HeaderItem = ({ item }: HeaderItemProps): JSX.Element => (
  <HeaderItemStyled>
    {item.link ? (
      <LinkAccessible to={item.link}>
        <ItemText item={item} />
      </LinkAccessible>
    ) : (
      <ItemText item={item} />
    )}
  </HeaderItemStyled>
);

const ItemText = ({ item }: HeaderItemProps): JSX.Element => (
  <ItemTextContainer>
    {item.icon}
    {item.hideLabel ? <SrOnly>{item.label}</SrOnly> : <HeaderItemLabel>{item.label}</HeaderItemLabel>}
  </ItemTextContainer>
);
