import { useTranslation } from 'react-i18next';

import { PageLayout } from 'components/PageLayout';
import { Container } from 'config/globalStyles';

import { routes } from 'app/router/routes';
import { useWebTitle } from 'hooks/useWebTitle';

import { ActivateAccountContainer, ActivateMessageTitle, BenefitList, BenefitTitle, LoginButton, TextContainer } from './style';
import { AccountIcon } from 'icons';

export const ActivateAccount = (): JSX.Element => {
  const { t } = useTranslation();
  useWebTitle('activateAccount');

  const items = [{ icon: <AccountIcon />, label: t('menu:userAccount') }];

  return (
    <ActivateAccountContainer>
      <PageLayout items={items} hideUserDropdown>
        <Container size='large'>
          <TextContainer>
            <ActivateMessageTitle>{t('activateAccount:title')}</ActivateMessageTitle>
            <p>{t('activateAccount:active')}</p>
            <article>
              <BenefitTitle>{t('activateAccount:benefits.title')}</BenefitTitle>
              <BenefitList>
                <li>{t('activateAccount:benefits.limit')}</li>
                <li>{t('activateAccount:benefits.group')}</li>
                <li>{t('activateAccount:benefits.apiKey')}</li>
              </BenefitList>
            </article>
            <LoginButton to={routes.login()}>{t('auth:login')}</LoginButton>
          </TextContainer>
        </Container>
      </PageLayout>
    </ActivateAccountContainer>
  );
};
