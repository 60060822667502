import { useContext, useRef } from 'react';
import { fromLonLat, toLonLat } from 'ol/proj';
import { useTranslation } from 'react-i18next';

import { ImageMapContext } from './ImageMap/ImageMapContext';

import { DownIcon, LeftIcon, RightIcon, UpIcon } from 'icons';
import { ContainerArrows, ControlButton } from './styles';

type Direction = 'left' | 'right' | 'up' | 'down';

export const MoveView = (): JSX.Element => {
  const { t } = useTranslation();
  const intervalRef = useRef<null | NodeJS.Timer>(null);
  const { map } = useContext(ImageMapContext);

  const startMoving = (direction: Direction) => {
    if (intervalRef.current) return;
    moveView(direction);
    intervalRef.current = setInterval(() => {
      moveView(direction);
    }, 500);
  };

  const stopMoving = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const moveView = (direction: Direction) => {
    const prevCenter = map?.getView().getCenter();

    if (prevCenter) {
      const step = 0.002;
      const centerLatLon = toLonLat(prevCenter);

      switch (direction) {
        case 'left':
          centerLatLon[0] -= step;
          break;
        case 'right':
          centerLatLon[0] += step;
          break;
        case 'up':
          centerLatLon[1] += step;
          break;
        case 'down':
          centerLatLon[1] -= step;
          break;
      }

      map?.getView().setCenter(fromLonLat(centerLatLon));
    }
  };

  return (
    <ContainerArrows>
      <ControlButton
        onClick={() => moveView('left')}
        onMouseDown={() => startMoving('left')}
        onMouseUp={stopMoving}
        onMouseLeave={stopMoving}
        aria-label={t('images:controls.moveLeft')}
      >
        <LeftIcon />
      </ControlButton>
      <ControlButton
        onClick={() => moveView('up')}
        onMouseDown={() => startMoving('up')}
        onMouseUp={stopMoving}
        onMouseLeave={stopMoving}
        aria-label={t('images:controls.moveUp')}
      >
        <UpIcon />
      </ControlButton>
      <ControlButton
        onClick={() => moveView('down')}
        onMouseDown={() => startMoving('down')}
        onMouseUp={stopMoving}
        onMouseLeave={stopMoving}
        aria-label={t('images:controls.moveDown')}
      >
        <DownIcon />
      </ControlButton>
      <ControlButton
        onClick={() => moveView('right')}
        onMouseDown={() => startMoving('right')}
        onMouseUp={stopMoving}
        onMouseLeave={stopMoving}
        aria-label={t('images:controls.moveRight')}
      >
        <RightIcon />
      </ControlButton>
    </ContainerArrows>
  );
};
