import { useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import type { IApiKeyAdminPanelList } from '../types';

import { useWindowSize } from 'hooks/useWindowSize';
import { formatIsoToDisplay } from 'helpers/dates';
import { routes } from 'app/router/routes';

import { SettingsIcon } from 'icons';
import {
  ListItemContainer,
  PropertyName,
  PropertyCol,
  InformationCol,
  PropertiesCol,
  ActionCol,
  LinkAccessible,
} from '../styles';

export interface ListItemProps {
  item: IApiKeyAdminPanelList;
}

export const ListItem = ({ item }: ListItemProps): JSX.Element => {
  const { t } = useTranslation();

  const size = useWindowSize();

  const isMobileResolution: boolean = useMemo(() => (size?.width ? size.width < 768 : false), [size.width]);

  return (
    <ListItemContainer>
      <Row>
        <InformationCol md={22} xs={23}>
          <Row wrap={true}>
            <Col md={6} xs={24}>
              <Typography.Title level={2} ellipsis={isMobileResolution ? { rows: 2 } : true}>
                {item.name}
              </Typography.Title>
            </Col>

            <PropertiesCol md={9} xs={12}>
              <Row>
                <PropertyCol md={14} xs={24}>
                  <PropertyName>{t('adminPanel:apiKeys.email')}: </PropertyName>
                  {item.email}
                </PropertyCol>
                <PropertyCol md={10} xs={24}>
                  <PropertyName>{t('adminPanel:apiKeys.status')}: </PropertyName>
                  {t(`adminPanel:apiKeys.apiKeyStatus.${item.status}`, { defaultValue: '' })}
                </PropertyCol>
              </Row>
            </PropertiesCol>

            <PropertyCol md={9} xs={12}>
              <Row>
                <PropertyCol md={12} xs={24}>
                  <PropertyName>{t('adminPanel:apiKeys.applicationDate')}: </PropertyName>
                  {formatIsoToDisplay(item.createdAt)}
                </PropertyCol>
                <PropertyCol md={12} xs={24}>
                  <PropertyName>{t('adminPanel:apiKeys.decisionDate')}: </PropertyName>
                  {formatIsoToDisplay(item.createdAt)}
                </PropertyCol>
              </Row>
            </PropertyCol>
          </Row>
        </InformationCol>

        <ActionCol md={2} xs={1}>
          <LinkAccessible to={routes.apiKeyDetails(item._id)}>
            <SettingsIcon />
          </LinkAccessible>
        </ActionCol>
      </Row>
    </ListItemContainer>
  );
};
