import { Col, Row, Select } from 'antd';
import { RefObject, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash.isempty';

import type { IImageConnected } from 'models/Image';

import { SrOnly } from 'components/SrOnly/style';
import { DownloadButton } from 'components/DownloadButton';
import { ImageConnected } from '../ImageConnected';
import { MoveView } from '../MoveView';

import { ZOOMS } from 'config/settings';

import { ChevronDownIcon, ChevronUpIcon, FullScreenIcon, MinusIcon, PlusIcon, FullScreenCloseIcon } from 'icons';

import {
  ConnectedImagesButton,
  ConnectedImagesLabel,
  ControlButton,
  ControlContainerZoom,
  ControlContainerButtons,
  ControlsCol,
  FullScreenButton,
  ImageControlsStyled,
  Separator,
  SliderContainer,
  StyledSlider,
  ZoomView,
  ContainerZoom,
  ContainerZoomMobile,
  StyledSelectMobile,
} from '../styles';
import { ImageMapContext } from './ImageMapContext';

interface ControlPanelProps {
  dicomDownloadSize: number;
  jpgDownloadSize: number;
  visibleConnectedImages: boolean;
  connectedImages: IImageConnected[];
  handleVisibleConnectedImages: () => void;
  mapContainerRef: RefObject<HTMLDivElement>;
}
export const ControlPanel = ({
  visibleConnectedImages,
  handleVisibleConnectedImages,
  connectedImages,
  dicomDownloadSize,
  jpgDownloadSize,
  mapContainerRef,
}: ControlPanelProps): JSX.Element => {
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(1);
  const { map } = useContext(ImageMapContext);

  document.querySelector('.ant-slider-handle')?.setAttribute('aria-label', t('images:controls.zoomSlider'));

  map?.on('moveend', () => {
    setZoom(prev => map.getView().getZoom() ?? prev);
  });

  const changeZoom = (zoomX: number) => {
    if (zoomX >= ZOOMS[0] && zoomX <= ZOOMS[ZOOMS.length - 1]) {
      map?.getView().setZoom(zoomX);
      setZoom(zoomX);
    }
  };

  const fillView = () => {
    if (mapContainerRef.current) {
      fullscreenMode ? document.exitFullscreen() : mapContainerRef.current.requestFullscreen();
      setFullscreenMode(prevState => !prevState);
    }
  };

  document.onfullscreenchange = () => {
    if (!document.fullscreenElement && fullscreenMode) {
      setFullscreenMode(false);
    }
  };

  return (
    <>
      {map && (
        <ImageControlsStyled visibleConnectedImages={visibleConnectedImages}>
          <Row justify='space-between'>
            <ControlsCol>
              <ContainerZoom>
                <ZoomView>{zoom.toPrecision(2)}x</ZoomView>
                <Separator />
                <ControlContainerZoom>
                  {ZOOMS.map(zoom => (
                    <ControlButton key={zoom} onClick={() => changeZoom(zoom)}>
                      <span>{zoom}x</span>
                    </ControlButton>
                  ))}
                </ControlContainerZoom>
                <Separator />
              </ContainerZoom>
              <ContainerZoomMobile>
                <SrOnly>
                  <label htmlFor='zoom'>{t('images:controls.zoom')}</label>
                </SrOnly>
                <StyledSelectMobile
                  value={`${zoom.toPrecision(2)}x`}
                  onChange={e => {
                    changeZoom(Number(e));
                  }}
                  id='zoom'
                >
                  {ZOOMS.map(zoom => (
                    <Select.Option key={zoom}>{zoom}x</Select.Option>
                  ))}
                </StyledSelectMobile>
                <Separator />
              </ContainerZoomMobile>

              <SliderContainer>
                <ControlButton onClick={() => changeZoom(zoom - 1)} aria-label={t('images:controls.zoomOut')}>
                  <MinusIcon />
                </ControlButton>
                <StyledSlider defaultValue={zoom} value={zoom} min={1} max={ZOOMS.at(-1)} onChange={changeZoom} />
                <ControlButton onClick={() => changeZoom(zoom + 1)} aria-label={t('images:controls.zoomIn')}>
                  <PlusIcon />
                </ControlButton>
                <Separator />
              </SliderContainer>

              <ControlContainerButtons>
                <MoveView />
                <FullScreenButton onClick={fillView} aria-label={t('images:controls.fullscreen')}>
                  {fullscreenMode ? (
                    <FullScreenCloseIcon aria-label={t('images:controls.fullscreenClose')} />
                  ) : (
                    <FullScreenIcon aria-label={t('images:controls.fullscreenOpen')} />
                  )}
                </FullScreenButton>
              </ControlContainerButtons>
            </ControlsCol>
            <Col>
              {id ? (
                <Row>
                  <DownloadButton
                    id={id}
                    direction='horizontal'
                    sizeDicom={dicomDownloadSize}
                    sizeJpg={jpgDownloadSize}
                    tooltipPlacement='top'
                  />
                  <Separator />
                  <ConnectedImagesButton
                    onClick={handleVisibleConnectedImages}
                    aria-label={t('images:controls.toggleConnectedImages')}
                    disabled={!connectedImages || connectedImages.length === 0}
                  >
                    <ConnectedImagesLabel>{t('images:connectedImages')}</ConnectedImagesLabel>
                    {visibleConnectedImages ? <ChevronDownIcon /> : <ChevronUpIcon />}
                  </ConnectedImagesButton>
                </Row>
              ) : null}
            </Col>
          </Row>
          {!isEmpty(connectedImages) && <ImageConnected connectedImages={connectedImages} />}
        </ImageControlsStyled>
      )}
    </>
  );
};
