import { Button } from 'antd';
import styled from 'styled-components';

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const ActionButton = styled(Button)`
  text-transform: uppercase;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.fontColor};
  border-radius: ${({ theme }) => theme.borderRadius};

  &.ant-btn-primary {
    background-color: ${props => props.theme.palette.secondary.main};
    border-color: ${props => props.theme.palette.secondary.main};
  }

  &.ant-btn-text:not(.ant-btn-dangerous):hover {
    background-color: transparent;
    border-color: ${props => props.theme.palette.secondary.main};
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  &.ant-btn-dangerous {
    transition: 0s;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.common.newError};

    &:hover {
      transition: 0s;
      border-color: ${({ theme }) => theme.palette.common.newError};
      color: ${({ theme }) => theme.palette.common.newError};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const ApiKeyDescription = styled.span`
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 15px;
`;

export const ApiKeyTitle = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 15px;
  font-weight: 600;
`;
