import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import ceil from 'lodash.ceil';

import { Loading } from 'components/Loading';
import { DownloadLimit } from './DownloadLimit';

import { useImageDownloadMutation } from './queries';

import { DownloadIcon } from 'icons';
import { DownloadButtonStyled, SubText } from './styles';

interface DownloadButtonProps {
  id: string;
  sizeDicom: number;
  sizeJpg: number;
  tooltipPlacement: 'left' | 'top';
  direction?: 'vertical' | 'horizontal';
}

export const DownloadButton = ({ id, sizeDicom, sizeJpg, tooltipPlacement, direction }: DownloadButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { mutate: imageDownload, isPending, error } = useImageDownloadMutation();
  const [errorTooltipOpen, setErrorTooltipOpen] = useState(false);

  useEffect(() => {
    if (error) {
      setErrorTooltipOpen(true);
    }
  }, [error]);

  const handleDownload = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    imageDownload(id);
  };

  const size = useMemo(() => (sizeDicom ? sizeDicom : sizeJpg), [sizeDicom, sizeJpg]);

  return (
    <Loading loading={isPending}>
      <Popover
        content={
          !error &&
          (size ? t('images:downloadSize', { size: ceil(size / (1024 * 1024 * 1024), 1) }) : t('images:noDownloadContent'))
        } // size in GB
        overlayInnerStyle={size ? { width: '26rem' } : undefined}
        placement={tooltipPlacement}
      >
        <Popover
          content={<DownloadLimit error={error} setErrorTooltipOpen={setErrorTooltipOpen} />}
          open={errorTooltipOpen}
          placement={tooltipPlacement}
          overlayInnerStyle={error ? { maxWidth: '64vw', width: '42rem' } : undefined}
        >
          <DownloadButtonStyled onClick={handleDownload} direction={direction} disabled={!size}>
            <DownloadIcon aria-label={t('images:download')} />
            <SubText className='download-label'>{t('images:download')}</SubText>
          </DownloadButtonStyled>
        </Popover>
      </Popover>
    </Loading>
  );
};
