import { routes } from 'app/router/routes';
import i18n from 'config/i18n';
import type { ErrorResponse } from './http';

import { errorNotification, errorNotificationWithoutErrorCode } from './notification';

export const httpErrorHandler = async (response: Response) => {
  try {
    const errorResponse = await response.json();

    if (isErrorResponse(errorResponse)) {
      errorHandler(errorResponse, response.status);
    }

    throw new Error('Unknown response');
  } catch (e) {
    throwError('Internal Server Error', 500);
  }
};

export const downloadErrorHandler = async (response: Response) => {
  try {
    const errorResponse = await response.json();

    if (isErrorResponse(errorResponse)) {
      switch (errorResponse.status) {
        case 404:
          errorNotificationWithoutErrorCode(i18n.t('images:noDownloadContent'), i18n.t('images:noDownloadContentDescription'));
          return null;

        case 429:
          return errorResponse;

        default:
          return null;
      }
    }

    throw new Error('Unknown response');
  } catch (e) {
    throwError('Internal Server Error', 500);
  }
};

const isErrorResponse = (error: unknown): error is ErrorResponse => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'text' in error &&
    'name' in error &&
    ('errorCode' in error || 'status' in error)
  );
};
export const errorHandler = (errorResponse: ErrorResponse, errorStatus: Response['status']) => {
  switch (errorStatus) {
    case 401:
      errorNotification(errorResponse.errorCode);
      redirectLogin(errorResponse.errorCode);
      break;
    case 403:
      throwError('No Access', 403);
      break;
    case 404:
      throwError('Not Found', 404);
      break;
    case 422:
      errorNotification(errorResponse.errorCode);
      break;
    case 429:
      errorNotification(errorResponse.errorCode);
      break;
    case 500:
      throwError('Internal Server Error', 500);
      break;
    default:
      break;
  }
};

const throwError = (name: string, status: 403 | 404 | 500) => {
  throw new Response(name, { status });
};

const redirectLogin = (errorCodes: ErrorResponse['errorCode']) => {
  // invalid token || expired token
  if (
    (errorCodes.includes(1102) || errorCodes.includes(1103)) &&
    (location.pathname.includes(routes.account()) || location.pathname.includes(routes.adminPanel()))
  ) {
    location.replace(routes.login());
  }
};
