import { Button } from 'antd';
import styled from 'styled-components';

import { commonFocusCss } from 'config/globalStyles';

export const Title = styled.h1`
  font-size: 2rem;
`;

export const StyledButton = styled(Button)`
  float: right;
  border-radius: 8px;

  &:focus {
    opacity: 80%;
    ${commonFocusCss};
  }
`;

export const FormContainer = styled.div`
  width: 40%;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    width: 60%;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 100%;
  }
`;
