import { RadioNavigationContainer, RadioNavigationItem } from './style';

interface RadioNavigationProps {
  items: {
    title: string;
    to: string;
    active?: boolean;
  }[];
}

export const RadioNavigation = ({ items }: RadioNavigationProps) => {
  return (
    <RadioNavigationContainer>
      {items.map(item => (
        <RadioNavigationItem key={item.to} to={item.to} activeItem={item.active}>
          {item.active ? <h1>{item.title}</h1> : item.title}
        </RadioNavigationItem>
      ))}
    </RadioNavigationContainer>
  );
};
