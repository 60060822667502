import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColHeader, ListHeaderContainer } from '../styles';

export const ListHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ListHeaderContainer>
      <Col md={22}>
        <Row>
          <ColHeader md={6}>{t('adminPanel:users.name')}</ColHeader>

          <Col md={10}>
            <Row>
              <ColHeader md={12}>{t('adminPanel:users.email')}</ColHeader>
              <ColHeader md={6}>{t('adminPanel:users.role')}</ColHeader>
              <ColHeader md={6}>{t('adminPanel:users.apiKey')}</ColHeader>
            </Row>
          </Col>

          <Col md={8}>
            <Row>
              <ColHeader md={6}>{t('adminPanel:users.limit')}</ColHeader>
              <ColHeader md={6}>{t('adminPanel:users.status')}</ColHeader>
              <ColHeader md={12}>{t('adminPanel:users.registerDate')}</ColHeader>
            </Row>
          </Col>
        </Row>
      </Col>

      <ColHeader md={2}>{t('adminPanel:users.edit')}</ColHeader>
    </ListHeaderContainer>
  );
};
