import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import type { IApiKeyRequest, IBasicInformation, IPasswordChange } from './types';

import { serviceAccountPanel } from './service';
import { successNotification } from 'helpers/notification';
import { routes } from 'app/router/routes';

export const useBasicInformationMutation = (handleEditable: () => void) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IBasicInformation) => serviceAccountPanel.basicInformation(data),
    onSuccess: () => {
      successNotification(t('accountPanel:basicInformation.changeSuccess'));
      queryClient.invalidateQueries({
        queryKey: ['auth', 'whoami'],
        exact: true,
      });
      handleEditable();
    },
  });
};

export const usePasswordChangeMutation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: IPasswordChange) => serviceAccountPanel.passwordChange(data),
    onSuccess: () => {
      successNotification(t('accountPanel:passwordChange.changeSuccess'));
      navigate(routes.basicInformation());
    },
  });
};

export const useApiKeyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IApiKeyRequest) => serviceAccountPanel.requestApiKey(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['apiKey', 'accountPanel'],
      });
    },
  });
};

export const useApiKeyQuery = () =>
  useQuery({
    queryKey: ['apiKey', 'accountPanel'],
    queryFn: () => serviceAccountPanel.getApiKey(),
    retry: false,
  });
