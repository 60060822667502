import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { IImageLayerInfo, IOverviewMapInfo } from '../types';
import type { IImageConnected } from 'models/Image';

import { Empty } from 'components/Empty';
import { Loading } from 'components/Loading';
import { ImageDetails } from '../../ImageDetails';
import { Map } from '../Map';
import { Layers } from '../Layers';
import { ImageLayer } from '../Layers/ImageLayer';
import { Controls } from '../Controls';
import { ScaleLine } from '../Controls/ScaleLine';
import { OverviewMapJpgBase } from '../Controls/OverviewMapJpgBase';
import { ControlPanel } from '../ControlPanel';

import { JPG_URL } from 'config/settings';
import { Image as ImageModel } from 'models/Image';

import { StyledMap } from '../../styles';

interface JpgBaseProps {
  image: ImageModel;
  connectedImages: IImageConnected[];
}

export const JpgBase = ({ image, connectedImages }: JpgBaseProps): JSX.Element => {
  const { jpgBasePath } = image;
  const [visibleConnectedImages, setVisibleConnectedImages] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [imageLayerInfo, setImageLayerInfo] = useState<IImageLayerInfo>();
  const [imageLayerLoading, setImageLayerLoading] = useState(true);
  const [overviewMapInfo, setOverviewMapInfo] = useState<IOverviewMapInfo>();
  const jpgUrl = useMemo(() => `${JPG_URL}/${jpgBasePath}`, [jpgBasePath]);

  const getImageLayerInfo = useCallback(async () => {
    try {
      setImageLayerLoading(true);
      const img = new Image();
      img.src = jpgUrl;
      await img.decode();
      setImageLayerInfo({ height: img.naturalHeight, width: img.naturalWidth });
      setImageLayerLoading(prevState => !prevState);
    } catch (e) {
      setImageLayerLoading(false);
    }
  }, [jpgUrl]);

  useEffect(() => {
    if (!jpgBasePath) {
      setImageLayerLoading(false);
      return;
    }
    getImageLayerInfo();
  }, [getImageLayerInfo, jpgBasePath]);

  const handleOverviewMapInfo = useCallback((height: number, width: number) => {
    setOverviewMapInfo({ width: width.toFixed().concat('px'), height: height.toFixed().concat('px') });
  }, []);

  const handleVisibleConnectedImages = () => {
    setVisibleConnectedImages(prevState => !prevState);
  };

  return (
    <StyledMap ref={mapContainerRef} overviewMapInfo={overviewMapInfo}>
      <ImageDetails image={image} visibleConnectedImages={visibleConnectedImages} visibleMap={Boolean(jpgBasePath)} />
      {jpgBasePath && imageLayerInfo ? (
        <Map>
          <Layers>
            <ImageLayer jpgUrl={jpgUrl} imageLayerInfo={imageLayerInfo} />
          </Layers>
          <Controls>
            <ScaleLine />
            <OverviewMapJpgBase jpgUrl={jpgUrl} imageLayerInfo={imageLayerInfo} handleOverviewMapInfo={handleOverviewMapInfo} />
          </Controls>
          <ControlPanel
            connectedImages={connectedImages}
            dicomDownloadSize={image.dicomDownloadSize}
            jpgDownloadSize={image.jpgDownloadSize}
            visibleConnectedImages={visibleConnectedImages}
            handleVisibleConnectedImages={handleVisibleConnectedImages}
            mapContainerRef={mapContainerRef}
          />
        </Map>
      ) : (
        <Loading loading={imageLayerLoading}>
          <Empty />
        </Loading>
      )}
    </StyledMap>
  );
};
