import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

import type { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import type { IApiKeyAdminPanel } from 'pages/AdminPanel/types';
import type { IDataPurposeSelect } from '.';

import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { DataPurpose } from 'pages/AccountPanel/types';

import { useBlockApiKeyMutation, useDeclineApiKeyMutation, useGenerateApiKeyMutation } from 'pages/AdminPanel/queries';

import { ActionButton, ActionButtonsContainer, FormNotClickable } from '../../styles';

export interface PendingApiKeyProps {
  apiKeyId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IApiKeyAdminPanel, any>;
  errors: FieldErrors<IApiKeyAdminPanel>;
  dataPurposeOptions: IDataPurposeSelect[];
  watch: UseFormWatch<IApiKeyAdminPanel>;
}

export const PendingApiKey = ({ apiKeyId, control, errors, dataPurposeOptions, watch }: PendingApiKeyProps) => {
  const { t } = useTranslation();
  const { mutate: generateApiKey } = useGenerateApiKeyMutation();
  const { mutate: declineApiKey } = useDeclineApiKeyMutation();
  const { mutate: blockApiKey } = useBlockApiKeyMutation();

  return (
    <Col md={12} xs={24}>
      <FormNotClickable>
        <Input
          name='name'
          label={t('adminPanel:apiKeys.name')}
          control={control}
          errors={errors}
          restProps={{ readOnly: true }}
        />
        <Input
          name='email'
          label={t('adminPanel:apiKeys.email')}
          control={control}
          errors={errors}
          restProps={{ readOnly: true }}
        />
        <Select
          name='dataPurpose'
          label={t('adminPanel:apiKeys.dataPurpose')}
          control={control}
          errors={errors}
          options={dataPurposeOptions}
          restProps={{ mode: 'multiple', open: false, showSearch: false }}
        />
        {watch('dataPurpose')?.includes(DataPurpose.OTHERS) && (
          <Input
            name='dataPurposeOtherDescription'
            label={t('adminPanel:apiKeys.dataPurposeOtherDescription')}
            control={control}
            errors={errors}
          />
        )}
        <Input
          name='companyName'
          label={t('adminPanel:apiKeys.companyName')}
          control={control}
          errors={errors}
          optional
          restProps={{ readOnly: true }}
        />
        <Input
          name='jobTitle'
          label={t('adminPanel:apiKeys.jobTitle')}
          control={control}
          errors={errors}
          optional
          restProps={{ readOnly: true }}
        />
        <Input
          name='createdAt'
          label={t('adminPanel:apiKeys.applicationDate')}
          control={control}
          errors={errors}
          restProps={{ readOnly: true }}
        />
      </FormNotClickable>
      <ActionButtonsContainer>
        <ActionButton type='text' danger onClick={() => blockApiKey(apiKeyId)}>
          {t('adminPanel:apiKeys.block')}
        </ActionButton>
        <ActionButton type='text' onClick={() => declineApiKey(apiKeyId)}>
          {t('adminPanel:apiKeys.decline')}
        </ActionButton>
        <ActionButton type='primary' onClick={() => generateApiKey(apiKeyId)}>
          {t('adminPanel:apiKeys.assign')}
        </ActionButton>
      </ActionButtonsContainer>
    </Col>
  );
};
