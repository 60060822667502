import { createBrowserRouter, redirect } from 'react-router-dom';

import type { RouteObject } from 'react-router-dom';

import { routeParam, routes, subRoutes } from './routes';
import { requireAdminSession, requireUserSession } from './loaders';

import { ErrorBoundary } from 'pages/ErrorBoundary';
import { ErrorPage } from 'pages/ErrorPage';
import { Home } from 'pages/Home';
import { Images } from 'pages/Images';
import { Image } from 'pages/Image';
import { Contact } from 'pages/Contact';
import { ApiKeyInformation } from 'pages/ApiKeyInformation';
import { Login } from 'pages/Auth/Login';
import { Register } from 'pages/Auth/Register';
import { ActivateAccount } from 'pages/Auth/ActivateAccount';
import { BasicInformation } from 'pages/AccountPanel/BasicInformation';
import { PasswordChange } from 'pages/AccountPanel/PasswordChange';
import { ApiKey } from 'pages/AccountPanel/ApiKey';
import { Users } from 'pages/AdminPanel/Users';
import { ApiKeys } from 'pages/AdminPanel/ApiKeys';
import { ApiKeysDetails } from 'pages/AdminPanel/ApiKeys/Details';

const paths: RouteObject[] = [
  {
    path: routes.base(),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: subRoutes.images,
        children: [
          {
            index: true,
            element: <Images />,
          },
          {
            path: `:${routeParam.id}`,
            element: <Image />,
          },
        ],
      },
      {
        path: subRoutes.contact,
        element: <Contact />,
      },
      {
        path: subRoutes.apiKeyInformation,
        element: <ApiKeyInformation />,
      },
      {
        path: subRoutes.auth,
        children: [
          {
            index: true,
            loader: () => redirect(routes.login()),
          },
          {
            path: subRoutes.login,
            element: <Login />,
          },
          {
            path: subRoutes.register,
            element: <Register />,
          },
          {
            path: subRoutes.activateAccount,
            element: <ActivateAccount />,
          },
        ],
      },
      {
        path: subRoutes.account,
        loader: requireUserSession,
        children: [
          {
            index: true,
            loader: () => redirect(routes.basicInformation()),
          },
          {
            path: subRoutes.basicInformation,
            element: <BasicInformation />,
          },
          {
            path: subRoutes.passwordChange,
            element: <PasswordChange />,
          },
          {
            path: subRoutes.apiKey,
            element: <ApiKey />,
          },
        ],
      },
      {
        path: subRoutes.adminPanel,
        loader: requireAdminSession,
        children: [
          {
            index: true,
            loader: () => redirect(routes.users()),
          },
          {
            path: subRoutes.users,
            element: <Users />,
          },
          {
            path: subRoutes.apiKeys,
            children: [
              {
                index: true,
                element: <ApiKeys />,
              },
              {
                path: `:${routeParam.id}`,
                element: <ApiKeysDetails />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routes.noAccess(),
    element: <ErrorPage type={403} />,
  },
  {
    path: '*',
    element: <ErrorPage type={404} />,
  },
];

export const browserRouter = createBrowserRouter(paths);
