enum Auth {
  token = 'token',
}

export const getAuthToken = () => localStorage.getItem(Auth.token);

export const setAuthToken = (token: string) => {
  localStorage.setItem(Auth.token, token);
};

export const removeAuthToken = () => {
  localStorage.removeItem(Auth.token);
};
