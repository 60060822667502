import { DefaultTheme } from 'styled-components';

export const themeDefault: DefaultTheme = {
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
      error: '#e60000',
      antdBorder: '#d9d9d9',
      antdFocusBorder: '#3f4d63',
      placeholder: '#757575',
      newError: '#C11059',
    },
    grayscale: {
      main: '#f9f9f9',
      fontColor: '#000000',
    },
    grayscaleDarker: {
      main: '#DDD',
      fontColor: '#000000',
    },
    grayscaleDark: {
      main: '#999',
      fontColor: '#000000',
    },
    primary: {
      main: '#ffffff',
      fontColor: '#283856',
    },
    secondary: {
      main: '#19DDA0',
      fontColor: '#283856',
    },
  },
  font: {
    fontSize: '10px',
    fontFamily: '"Roboto", sans-serif',
    color: {
      black_45: '#00000073',
      black_60: '#00000099',
      black_70: '#000000B2',
      black_80: '#000000CC',
      black_85: '#000000D9',
    },
  },
  breakpoints: {
    small: '576px',
    medium: '768px',
    large: '1200px',
  },
  borderRadius: '1.2rem',
  shadow: '0px 22px 44px #0000000D;',
};
