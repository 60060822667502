import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';

import { UserRole } from 'config/userRole';
import { UserStatus } from '../types';

import { ActivateUserIcon, BlockUserIcon, DeleteUserIcon, GrantAdminIcon, RevokeAdminIcon, UnblockUserIcon } from 'icons';
import { EditDropdownItemDelete } from '../styles';
import {
  useActivateUserMutation,
  useBlockUserMutation,
  useDeleteUserMutation,
  useGrantAdminMutation,
  useRevokeAdminMutation,
  useUnblockUserMutation,
} from '../queries';

export const useEditDropdownItems = (status: UserStatus, role: UserRole, userId: string) => {
  const { t } = useTranslation();
  const { mutate: grantAdmin } = useGrantAdminMutation();
  const { mutate: revokeAdmin } = useRevokeAdminMutation();
  const { mutate: activateUser } = useActivateUserMutation();
  const { mutate: blockUser } = useBlockUserMutation();
  const { mutate: unblockUser } = useUnblockUserMutation();
  const { mutate: deleteUser } = useDeleteUserMutation();

  const activateUserItem = {
    label: t('adminPanel:users.activate'),
    key: 'activateUser',
    icon: <ActivateUserIcon />,
    onClick: () => activateUser(userId),
  };

  const grantAdminItem = {
    label: t('adminPanel:users.grantAdmin'),
    key: 'grantAdminPrivileges',
    icon: <GrantAdminIcon />,
    onClick: () => grantAdmin(userId),
  };

  const revokeAdminItem = {
    label: t('adminPanel:users.revokeAdmin'),
    key: 'revokeAdminPrivileges',
    icon: <RevokeAdminIcon />,
    onClick: () => revokeAdmin(userId),
  };

  const blockUserItem = {
    label: t('adminPanel:users.block'),
    key: 'blockUser',
    icon: <BlockUserIcon />,
    onClick: () => blockUser(userId),
  };

  const unblockUserItem = {
    label: t('adminPanel:users.unblock'),
    key: 'unblockUser',
    icon: <UnblockUserIcon />,
    onClick: () => unblockUser(userId),
  };

  const deleteUserItem = {
    label: <EditDropdownItemDelete>{t('adminPanel:users.delete')}</EditDropdownItemDelete>,
    key: 'deleteUser',
    icon: <DeleteUserIcon />,
    onClick: () => deleteUser(userId),
  };

  const divider = {
    type: 'divider' as const,
  };

  const basicMenu: MenuProps['items'] = [
    { ...divider },
    status === UserStatus.BLOCKED ? { ...unblockUserItem } : { ...blockUserItem },
    { ...deleteUserItem },
  ];

  const inactiveMenu: MenuProps['items'] = [{ ...activateUserItem }, ...basicMenu];

  const adminMenu: MenuProps['items'] = [{ ...revokeAdminItem }, ...basicMenu];

  const userMenu: MenuProps['items'] = [{ ...grantAdminItem }, ...basicMenu];

  return status === UserStatus.INACTIVE ? inactiveMenu : role === UserRole.ADMIN ? adminMenu : userMenu;
};
