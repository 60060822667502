import { Dropdown } from 'antd';
import { EditIcon } from 'icons';

import { UserStatus } from '../types';
import { UserRole } from 'config/userRole';

import { EditDropdownContainer, EditDropdownContent } from '../styles';
import { useEditDropdownItems } from './useEditDropdownItems';

interface EditDropdownProps {
  status: UserStatus;
  role: UserRole;
  userId: string;
}

export const EditDropdown = ({ status, role, userId }: EditDropdownProps) => {
  const items = useEditDropdownItems(status, role, userId);

  return (
    <EditDropdownContainer>
      <Dropdown menu={{ items }} trigger={['click']} arrow={{ pointAtCenter: true }} placement='bottomRight'>
        <EditDropdownContent onClick={e => e.preventDefault()}>
          <EditIcon />
        </EditDropdownContent>
      </Dropdown>
    </EditDropdownContainer>
  );
};
