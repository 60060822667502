import { useTranslation } from 'react-i18next';

import { routes } from 'app/router/routes';
import { useWebTitle } from 'hooks/useWebTitle';

import { Footer } from 'components/Footer';
import { Logo } from 'components/Logo';

import { BigHeader, HomeButton, HomePage, SubHeader, TextContainer } from './styles';

export const Home = (): JSX.Element => {
  const { t } = useTranslation();
  useWebTitle('home');

  return (
    <HomePage>
      <TextContainer>
        <Logo size='large' />
        <BigHeader>{t('home:header')}</BigHeader>
        <SubHeader>{t('home:subheader')}</SubHeader>
        <HomeButton to={routes.images()}>{t('menu:list')}</HomeButton>
        <Footer />
      </TextContainer>
    </HomePage>
  );
};
