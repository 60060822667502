import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';
import type { IBasicInformation } from '../types';

import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { PageLayout } from 'components/PageLayout';
import { RadioNavigation } from 'components/RadioNavigation';
import { Container } from 'config/globalStyles';

import { useBasicInformationMutation } from '../queries';
import { useValidation } from 'hooks/useValidation';
import { useWebTitle } from 'hooks/useWebTitle';
import { useWhoAmIQuery } from 'pages/Auth/queries';
import { routes } from 'app/router/routes';

import { ActionButton, ActionButtonsContainer } from '../style';
import { UserAccountIcon } from 'icons';

export const BasicInformation = (): JSX.Element => {
  const [editable, setEditable] = useState(false);
  const { data: user } = useWhoAmIQuery();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<IBasicInformation>({
    defaultValues: { email: user?.email ?? '', firstName: user?.firstName ?? '', lastName: user?.lastName ?? '' },
  });
  const { t } = useTranslation();
  const { validate } = useValidation();
  const handleEditable = () => {
    setEditable(prevState => !prevState);
  };
  const { mutate: basicInformationSubmit, isPending } = useBasicInformationMutation(handleEditable);
  useWebTitle('basicInformation');

  const onSubmit: SubmitHandler<IBasicInformation> = data => {
    basicInformationSubmit(data);
  };

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
    }
  }, [setValue, user]);

  const items = [{ icon: <UserAccountIcon />, label: t('menu:userSettings') }];

  const navigationItems = [
    {
      title: t('accountPanel:basicInformation.title'),
      to: routes.basicInformation(),
      active: true,
    },
    {
      title: t('accountPanel:passwordChange.title'),
      to: routes.passwordChange(),
    },
    {
      title: t('accountPanel:apiKey.title'),
      to: routes.apiKey(),
    },
  ];

  return (
    <PageLayout items={items}>
      <Container size='normal'>
        <RadioNavigation items={navigationItems} />
        <Form<IBasicInformation> onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12} md={18} xs={24}>
              <Input
                name='email'
                label={t('accountPanel:basicInformation.email')}
                control={control}
                errors={errors}
                rules={validate('email')}
                restProps={{ readOnly: true, disabled: editable }}
              />
              <Input
                name='firstName'
                label={t('accountPanel:basicInformation.firstName')}
                control={control}
                errors={errors}
                rules={validate('required')}
                restProps={{ readOnly: !editable }}
              />
              <Input
                name='lastName'
                label={t('accountPanel:basicInformation.lastName')}
                control={control}
                errors={errors}
                rules={validate('required')}
                restProps={{ readOnly: !editable }}
              />
              <ActionButtonsContainer>
                {editable ? (
                  <>
                    <ActionButton type='text' key='cancel' onClick={handleEditable}>
                      {t('accountPanel:basicInformation.cancel')}
                    </ActionButton>
                    <ActionButton type='primary' loading={isPending} key='save' htmlType='submit'>
                      {t('accountPanel:basicInformation.save')}
                    </ActionButton>
                  </>
                ) : (
                  <>
                    <ActionButton type='text' key='delete' danger>
                      {t('accountPanel:basicInformation.deleteAccount')}
                    </ActionButton>
                    <ActionButton type='primary' key='update' onClick={handleEditable}>
                      {t('accountPanel:basicInformation.update')}
                    </ActionButton>
                  </>
                )}
              </ActionButtonsContainer>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageLayout>
  );
};
