import styled, { createGlobalStyle, css } from 'styled-components';
import 'antd/dist/antd.less';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${props => props.theme.font.fontSize};
  }
  body {
    margin: 0;
    font-family: ${props => props.theme.font.fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    min-height: 100%;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    &-thumb {
      background-color: ${props => props.theme.palette.grayscaleDark.main};
      border-radius: ${props => props.theme.borderRadius};
    }
  }

  .ant-layout {
    background-color: ${props => props.theme.palette.grayscale.main};
    color: ${props => props.theme.palette.primary.fontColor};
    min-height: 100%;
  }


  .image-preview {
    .ant-popover-inner {
      border: 1px solid ${props => props.theme.palette.grayscaleDarker.main};
      border-radius: 7px;
      overflow: hidden;
      box-shadow: none;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-arrow {
      display: none;
    }
  }
`;

interface ContainerProps {
  size?: 'small' | 'normal' | 'large';
}

export const Container = styled.div<ContainerProps>`
  padding: 5rem 2rem 0;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 2rem 2rem 0;
  }
`;

export const commonFocusCss = css`
  border-color: ${props => props.theme.palette.common.antdFocusBorder};
  box-shadow: 0 0 0 2px rgb(40 56 86 / 20%);
  outline: 0;
`;

export default GlobalStyle;
