import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListHeaderStyled, ListHeaderContainer } from '../styles';

export const ListHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ListHeaderContainer>
      <Col md={2}></Col>

      <Col md={20}>
        <Row>
          <ListHeaderStyled md={10}>{t('images:diagnosis')}</ListHeaderStyled>

          <Col md={10}>
            <Row>
              <ListHeaderStyled md={14}>{t('images:stain')}</ListHeaderStyled>
              <ListHeaderStyled md={10}>{t('images:tissue')}</ListHeaderStyled>
            </Row>
          </Col>

          <ListHeaderStyled md={4}>{t('images:preview')}</ListHeaderStyled>
        </Row>
      </Col>
    </ListHeaderContainer>
  );
};
