import { Button, Col, Row } from 'antd';
import styled from 'styled-components';

import { commonFocusCss } from 'config/globalStyles';
import { Link } from 'react-router-dom';

export const AdminPanelContainer = styled.div`
  .ant-pagination {
    li,
    .ant-pagination-prev button,
    .ant-pagination-next button,
    .ant-select-single .ant-select-selector {
      border: 0;
      border-radius: 2px;
      box-shadow: ${props => props.theme.shadow};
    }

    .ant-select-arrow {
      color: ${props => props.theme.palette.primary.fontColor};
    }

    .ant-pagination-item-active {
      background-color: ${props => props.theme.palette.secondary.main};
      font-weight: 600;
    }

    .ant-select-item-option-selected {
      background-color: ${props => props.theme.palette.secondary.main};
    }

    .ant-pagination-item,
    .ant-pagination-item-link,
    .ant-pagination-prev,
    .ant-pagination-next {
      &:focus {
        ${commonFocusCss}
      }
    }
  }
`;

export const ColHeader = styled(Col)`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 5rem;
  margin: 2rem 0 1rem;
`;

export const ListItemContainer = styled.div`
  background-color: ${props => props.theme.palette.common.white};
  line-height: 8.5rem;
  overflow: hidden;
  margin-bottom: 0.4rem;
  box-shadow: ${props => props.theme.shadow};
  border-radius: 12px;
  width: 100%;
  padding: 0rem 1rem;

  h2 {
    margin: 0;
    padding-right: 2rem;
    font-size: inherit;
    font-weight: inherit;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.medium}) {
      text-overflow: break-word;
      white-space: normal;
      line-height: normal;
      align-items: normal;

      margin-bottom: 1rem;
    }
  }

  .ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    padding: 2rem 1rem;
    line-height: normal;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;

  .ant-input-affix-wrapper,
  .ant-select {
    min-width: 248px;
  }

  .ant-select-clear {
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    flex-direction: column;
    width: 100%;

    .ant-form-item {
      margin-bottom: 5px;
    }
  }
`;

export const ListHeaderContainer = styled(Row)`
  padding: 0 1rem;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const PropertyName = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  display: none;
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: inline;
  }
`;

export const PropertyCol = styled(Col)`
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

export const InformationCol = styled(Col)`
  font-size: 1.5rem;
`;

export const PropertiesCol = styled(Col)`
  text-overflow: ellipsis;
`;

export const ActionCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const EditDropdownContainer = styled.div`
  user-select: none;
  display: flex;
  width: content;
  max-width: 100%;

  svg {
    max-width: 3.2rem;
  }
`;

export const EditDropdownContent = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const EditDropdownItemDelete = styled.span`
  color: ${({ theme }) => theme.palette.common.newError};
`;

export const LinkAccessible = styled(Link)`
  display: flex;
  width: content;
  height: content;
  margin-left: 2px;

  &:focus {
    ${commonFocusCss}
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const ActionButton = styled(Button)`
  text-transform: uppercase;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.fontColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  line-height: 0;

  &.ant-btn-primary {
    background-color: ${props => props.theme.palette.secondary.main};
    border-color: ${props => props.theme.palette.secondary.main};
  }

  &.ant-btn-text:not(.ant-btn-dangerous):hover {
    background-color: transparent;
    border-color: ${props => props.theme.palette.secondary.main};
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  &.ant-btn-dangerous {
    transition: 0s;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.common.newError};

    &:hover {
      transition: 0s;
      border-color: ${({ theme }) => theme.palette.common.newError};
      color: ${({ theme }) => theme.palette.common.newError};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
`;

export const FormNotClickable = styled.div`
  pointer-events: none;
`;
