import { useMutation } from '@tanstack/react-query';

import type { IContactForm } from 'models/Contact';

import { serviceContact } from './service';

export const useContactMutation = (handleContactFormCompleted: () => void) =>
  useMutation({
    mutationFn: (data: IContactForm) => serviceContact.sendMessage(data),
    onSuccess: handleContactFormCompleted,
  });
