import { Form as FormAntd } from 'antd';

import type { ReactNode, FormEvent } from 'react';
import type { FormProps as FormAntdProps } from 'antd';
import type { FieldValues } from 'react-hook-form';

interface FormProps<T> {
  onSubmit?: FormAntdProps<FormEvent<T>>['onFinish'];
  children: ReactNode;
}

export const Form = <T extends FieldValues>({ onSubmit, children }: FormProps<T>): JSX.Element => (
  <FormAntd onFinish={onSubmit} layout='vertical' requiredMark='optional'>
    {children}
  </FormAntd>
);
