import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations_en from 'i18n/translations_en.json';
import translations_pl from 'i18n/translations_pl.json';
import { Language, defaultLanguage, localStorageLanguageKey } from 'config/lang';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLanguage = (lang: any): lang is Language => {
  return Object.values(Language).includes(lang);
};

export const getDefaultLanguage = (): string => {
  const languageFromLocalStorage = localStorage.getItem(localStorageLanguageKey);
  const languageToReturn = process.env.REACT_APP_LANG ?? document.documentElement.lang ?? defaultLanguage;

  if (languageFromLocalStorage && isLanguage(languageFromLocalStorage)) {
    return languageFromLocalStorage;
  }

  return languageToReturn;
};

i18n.on('languageChanged', language => {
  document.documentElement.setAttribute('lang', language);
});

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      'en-US': translations_en,
      'pl-PL': translations_pl,
    },
    lng: getDefaultLanguage(),
    fallbackLng: Language.PL,
    returnNull: false,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
