import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import type { IUser } from 'models/Auth';

import { routes } from 'app/router/routes';

import { LoginButton, UserDropdownContainer, UserDropdownContent, UserEmail, UserInfo, UserName } from './styles';
import { useUserDropdownItems } from './useUserDropdownItems';

interface UserDropdownProps {
  user: IUser | undefined;
  loading: boolean;
  error: Error | null;
}

export const UserDropdown = ({ user, loading, error }: UserDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const items = useUserDropdownItems(user);

  return (
    <UserDropdownContainer>
      {user && !error ? (
        <Dropdown menu={{ items }} trigger={['click']} arrow={{ pointAtCenter: true }} placement='bottom'>
          <UserDropdownContent onClick={e => e.preventDefault()}>
            <DownOutlined />
            <UserInfo>
              <UserName>
                {user.firstName} {user.lastName}
              </UserName>
              <UserEmail>{user.email}</UserEmail>
            </UserInfo>
          </UserDropdownContent>
        </Dropdown>
      ) : loading ? (
        <></>
      ) : (
        <LoginButton to={routes.login()}>{t('auth:login')}</LoginButton>
      )}
    </UserDropdownContainer>
  );
};
