import { Result } from 'antd';
import styled from 'styled-components';

export const StyledResult = styled(Result)`
  height: 100%;
  width: 100%;

  .ant-btn {
    border-radius: 8px;
  }
`;
