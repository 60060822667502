export class Filters {
  filters: {[k: string]: string|number}

  constructor(filters: {[k: string]: string|number}) {
    this.filters = filters;
  }

  searchParams() {
    const qs = new URLSearchParams();
  
    Object.keys(this.filters).forEach(param => {
      if(this.filters[param]) {
        qs.append(param, this.filters[param].toString());
      }  
    });
    
    return qs.toString();
  }
}