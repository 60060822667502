import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { ErrorResponse } from 'helpers/http';

import { routes } from 'app/router/routes';

import {
  Recommendations,
  RecommendationsTitle,
  DownloadLimitDescription,
  DownloadLimitTitle,
  ActionButtonsContainer,
  ActionButton,
  TextContainer,
} from './styles';

interface DownloadLimitProps {
  error: ErrorResponse | null;
  setErrorTooltipOpen: (arg1: boolean) => void;
}

export const DownloadLimit = ({ error, setErrorTooltipOpen }: DownloadLimitProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modeLogged = useMemo(() => error?.errorCode && error.errorCode.includes(1501), [error]);

  const handleLogin = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate(routes.login());
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setErrorTooltipOpen(false);
  };

  return (
    error &&
    (modeLogged ? (
      <>
        <TextContainer>
          <DownloadLimitTitle>{t('downloadLimit:logged.title')}</DownloadLimitTitle>
          <DownloadLimitDescription>{t('downloadLimit:logged.description')}</DownloadLimitDescription>
          <article>
            <RecommendationsTitle>{t('downloadLimit:logged.recommendationsTitle')}</RecommendationsTitle>
            <Recommendations>
              <li>{t('downloadLimit:logged.recommendations.wait')}</li>
            </Recommendations>
          </article>
        </TextContainer>
        <ActionButtonsContainer>
          <ActionButton type='primary' onClick={handleCancel}>
            {t('downloadLimit:logged.buttonClose')}
          </ActionButton>
        </ActionButtonsContainer>
      </>
    ) : (
      <>
        <TextContainer>
          <DownloadLimitTitle>{t('downloadLimit:notLogged.title')}</DownloadLimitTitle>
          <DownloadLimitDescription>{t('downloadLimit:notLogged.description')}</DownloadLimitDescription>
          <article>
            <RecommendationsTitle>{t('downloadLimit:notLogged.recommendationsTitle')}</RecommendationsTitle>
            <Recommendations>
              <li>{t('downloadLimit:notLogged.recommendations.logIn')}</li>
              <li>{t('downloadLimit:notLogged.recommendations.wait')}</li>
            </Recommendations>
          </article>
        </TextContainer>
        <ActionButtonsContainer>
          <ActionButton type='text' onClick={handleCancel}>
            {t('downloadLimit:notLogged.buttonCancel')}
          </ActionButton>
          <ActionButton type='primary' onClick={handleLogin}>
            {t('downloadLimit:notLogged.buttonLogIn')}
          </ActionButton>
        </ActionButtonsContainer>
      </>
    ))
  );
};
