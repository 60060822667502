import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import { PageLayout } from 'components/PageLayout';
import { RadioNavigation } from 'components/RadioNavigation';
import { Loading } from 'components/Loading';
import { Container } from 'config/globalStyles';
import { AssignedApiKey } from './AssignedApiKey';
import { WaitingApiKey } from './WaitingApiKey';
import { FormApiKey } from './FormApiKey';

import { useApiKeyQuery } from '../queries';
import { useWebTitle } from 'hooks/useWebTitle';
import { routes } from 'app/router/routes';
import { ApiKeyStatus } from '../types';

import { UserAccountIcon } from 'icons';

export const ApiKey = (): JSX.Element => {
  const { data: apiKey, isLoading } = useApiKeyQuery();
  const { t } = useTranslation();
  useWebTitle('apiKey');

  const items = [{ icon: <UserAccountIcon />, label: t('menu:userSettings') }];

  const navigationItems = [
    {
      title: t('accountPanel:basicInformation.title'),
      to: routes.basicInformation(),
    },
    {
      title: t('accountPanel:passwordChange.title'),
      to: routes.passwordChange(),
    },
    {
      title: t('accountPanel:apiKey.title'),
      to: routes.apiKey(),
      active: true,
    },
  ];

  return (
    <PageLayout items={items}>
      <Container size='normal'>
        <RadioNavigation items={navigationItems} />
        <Loading loading={isLoading}>
          <Row>
            <Col lg={12} md={18} sm={24}>
              {apiKey ? (
                apiKey.status === ApiKeyStatus.PENDING ? (
                  <WaitingApiKey />
                ) : apiKey.status === ApiKeyStatus.GRANTED ? (
                  <AssignedApiKey keyValue={apiKey.keyValue} />
                ) : (
                  <FormApiKey />
                )
              ) : (
                <FormApiKey />
              )}
            </Col>
          </Row>
        </Loading>
      </Container>
    </PageLayout>
  );
};
