import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { commonFocusCss } from 'config/globalStyles';

export const HomePage = styled.div`
  color: ${props => props.theme.palette.primary.fontColor};
  background-image: url('/home-bg.png');
  background-repeat: no-repeat;
  background-position: 100% 70%;
  min-height: 100vh;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    background-position: 100% 30%;
    background-repeat: no-repeat;
    background-size: 500px;
  }

  @media (max-width: 900px) {
    background-size: 350px;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    background-size: 300px;
    background-position: 100% 30%;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    background-size: 200px;
    background-position: 100% 30%;
  }
`;

export const TextContainer = styled.div`
  padding: 5rem 3rem;

  @media (min-width: ${props => props.theme.breakpoints.large}) {
    height: 100%;
    padding-top: 17rem;
    padding-left: 8rem;
  }

  .footer {
    margin-top: 4rem;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      margin-top: 16rem;
    }

    @media (max-width: ${props => props.theme.breakpoints.small}) {
      margin-top: 8rem;
    }
  }
`;

export const BigHeader = styled.h1`
  font-weight: 300;
  font-size: 4rem;
  line-height: 1.25;
  margin-top: 8rem;
  width: 40%;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    font-size: 2.6rem;
    width: 60%;
    margin-bottom: 3.2rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 2.2rem;
    margin-top: 2rem;
    width: 70%;
    margin-bottom: 3.2rem;
  }
`;

export const SubHeader = styled.p`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-top: 1rem;
  max-width: 40%;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.3rem;
    width: 60%;
  }
`;

export const LinkAccessible = styled(Link)`
  &:focus {
    button {
      opacity: 80%;
      ${commonFocusCss};
    }
  }
`;

export const HomeButton = styled(Link)`
  background-color: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.secondary.fontColor};
  margin-top: 6rem;
  font-weight: 700;
  font-size: 1.7rem;
  text-transform: uppercase;
  display: flex;
  width: 30rem;
  height: 7rem;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => props.theme.shadow};
  border-radius: ${props => props.theme.borderRadius};

  :hover,
  :focus {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    height: 5rem;
  }
`;
