import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Loading } from 'components/Loading';
import { Header } from 'components/Header';
import { ImageMap } from './components/ImageMap';

import { routes } from 'app/router/routes';
import { useImageQuery } from './queries';
import { useWebTitle } from 'hooks/useWebTitle';

import { ListIcon } from 'icons';
import { ImageContainer } from './style';

interface LocationState {
  from?: string;
}

export const Image = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, isLoading } = useImageQuery();
  const location = useLocation();
  useWebTitle('details', data?.image?.diagnosis ?? '...');
  const locationState = location.state as LocationState;

  return (
    <ImageContainer>
      <Loading loading={isLoading}>
        {data && (
          <>
            <Header
              items={[
                { icon: <ListIcon />, label: t('menu:list'), link: locationState?.from ?? routes.images() },
                { label: data.image.diagnosis ?? '...' },
              ]}
            />
            {data.image && <ImageMap image={data.image} connectedImages={data.connectedImages} />}
          </>
        )}
      </Loading>
    </ImageContainer>
  );
};
