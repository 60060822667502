import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';

import { THUMBS_URL } from 'config/settings';

import { ListItemImageStyled } from '../styles';

interface ListItemImageProps {
  image: string | null;
}

export const ListItemImage = ({ image }: ListItemImageProps): JSX.Element => {
  const { t } = useTranslation();

  return image ? (
    <Popover
      content={<img src={`${THUMBS_URL}/${image}`} alt={t('images:preview')} />}
      trigger='hover'
      placement='leftBottom'
      overlayClassName='image-preview'
    >
      <ListItemImageStyled src={`${THUMBS_URL}/${image}`} alt={t('images:preview')} className='small-image' />
    </Popover>
  ) : (
    <>{t('images:noPreview')}</>
  );
};
