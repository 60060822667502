import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ApiKeyInformationContainer = styled.div`
  color: ${props => props.theme.palette.primary.fontColor};
  background-image: url('/home-bg.png');
  background-repeat: no-repeat;
  background-position: 100% 70%;
  min-height: 100vh;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    background-position: 100% 30%;
    background-repeat: no-repeat;
    background-size: 500px;
  }

  @media (max-width: 900px) {
    background-size: 350px;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    background-size: 300px;
    background-position: 100% 30%;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    background-size: 200px;
    background-position: 100% 30%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    margin-bottom: 10px;
  }
`;

export const ApiKeyInformationTitle = styled.h1`
  font-size: 24px;
`;

export const ActionButton = styled(Link)`
  text-transform: uppercase;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  font-size: 16px
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.fontColor};
  background-color: ${props => props.theme.palette.secondary.main};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: fit-content;

  :hover, :focus {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
