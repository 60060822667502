import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IApiKey } from '../types';

type AssignedApiKeyProps = Pick<IApiKey, 'keyValue'>;

export const AssignedApiKey = ({ keyValue }: AssignedApiKeyProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useForm<IApiKey>({ defaultValues: { keyValue } });

  return (
    <Form<IApiKey>>
      <Input.PasswordWithCopy name='keyValue' label={t('accountPanel:apiKey.assignedApiKey')} control={control} errors={errors} />
    </Form>
  );
};
