import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

import { commonFocusCss } from 'config/globalStyles';

interface DownloadButtonStyledProps extends ButtonProps {
  direction?: 'vertical' | 'horizontal';
}

export const DownloadButtonStyled = styled(Button)<DownloadButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-items: middle;
  padding: 0 1.5rem;
  border: 0;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  background: transparent;
  box-shadow: none;

  &:disabled {
    background: transparent;

    svg {
      opacity: 30%;
    }
  }

  &:focus {
    ${commonFocusCss}
  }

  ${props =>
    props.direction === 'vertical'
      ? `
      flex-direction: column;
      height: 5rem;
      line-height: 1.1rem;
      > svg {
        height: 3rem;
      }
      `
      : `
      height: 3rem;
      line-height: 3rem;
      > svg {
        margin: 0 0.5rem;
      }
      `}

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    justify-content: center;
  }
`;

export const SubText = styled.span`
  @media (max-width: ${props => props.theme.breakpoints.large}) {
    &.download-label {
      display: none;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  * {
    margin: 0;
    font-size: 1.5rem;
  }
`;

export const DownloadLimitTitle = styled.span`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.palette.common.newError};
`;

export const DownloadLimitDescription = styled.span`
  color: ${({ theme }) => theme.palette.common.newError};
`;

export const RecommendationsTitle = styled.span`
  :after {
    content: ':';
  }
`;

export const Recommendations = styled.ul`
  padding-left: 24px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column-reverse;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;

export const ActionButton = styled(Button)`
  text-transform: uppercase;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primary.fontColor};
  border-radius: ${({ theme }) => theme.borderRadius};

  &.ant-btn-primary {
    background-color: ${props => props.theme.palette.secondary.main};
    border-color: ${props => props.theme.palette.secondary.main};
  }

  &.ant-btn-text:hover {
    background-color: transparent;
    border-color: ${props => props.theme.palette.secondary.main};
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`;
