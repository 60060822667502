import { useRef, useState, useEffect, ReactNode } from 'react';
import { Map as MapOl, View as ViewOl } from 'ol';

import { ImageMapContext } from './ImageMapContext';

import { MapContainer } from '../styles';
import 'ol/ol.css';

interface MapProps {
  children: ReactNode;
}

export const Map = ({ children }: MapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<MapOl>();

  useEffect(() => {
    const options = {
      view: new ViewOl({}),
      layers: [],
      controls: [],
      overlays: [],
    };

    const mapObject = new MapOl(options);

    if (mapRef.current) {
      mapObject.setTarget(mapRef.current);
      setMap(mapObject);
    }

    return () => {
      mapObject.setTarget(undefined);
    };
  }, []);

  return (
    <ImageMapContext.Provider value={{ map }}>
      <MapContainer ref={mapRef}>{children}</MapContainer>
    </ImageMapContext.Provider>
  );
};
export default Map;
