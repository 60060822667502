import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const RadioNavigationContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grayscaleDark.main};
  padding: 4px;
  display: flex;
  gap: 4px;
  border-radius: ${({ theme }) => theme.borderRadius};
  width: fit-content;
  margin-bottom: 40px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: column;
    width: 100%;
  }
`;

interface RadioNavigationItemProps {
  activeItem?: boolean;
}

export const RadioNavigationItem = styled(Link)<RadioNavigationItemProps>`
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 8px 24px;
  border: 1px solid ${({ theme, activeItem }) => (activeItem ? theme.palette.primary.fontColor : theme.palette.primary.main)};

  h1 {
    font-size: 1.6rem;
    font-weight: 700;
    color: ${({ theme, activeItem }) => (activeItem ? theme.palette.primary.main : undefined)};
    margin: 0;
  }

  background-color: ${({ theme, activeItem }) => (activeItem ? theme.palette.primary.fontColor : undefined)};
  color: ${({ theme, activeItem }) => (activeItem ? theme.palette.primary.main : undefined)};

  :hover,
  :focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.fontColor};
    color: ${({ theme, activeItem }) => (activeItem ? theme.palette.primary.main : theme.palette.primary.fontColor)};
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 8px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: center;

    h1 {
      font-size: 1.4rem;
      font-weight: 700;
    }
  }
`;
