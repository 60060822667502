import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { View as ViewOl } from 'ol';
import { Tile as TileLayerOl } from 'ol/layer';
import { Projection as ProjectionOl } from 'ol/proj';
import { OverviewMap as OverviewMapOl } from 'ol/control';
import { Zoomify } from 'ol/source';
import { getCenter, getHeight, getWidth } from 'ol/extent';

import type { IOverviewMapInfo, ITileLayerInfo } from '../types';

import { ImageMapContext } from '../ImageMapContext';
import { useTranslation } from 'react-i18next';

interface OverviewMapProps {
  pixelSpacing: number;
  tilesUrl: string;
  setOverviewMapInfo: Dispatch<SetStateAction<IOverviewMapInfo | undefined>>;
  tileLayerInfo?: ITileLayerInfo;
}

export const OverviewMap = ({ pixelSpacing, tilesUrl, setOverviewMapInfo, tileLayerInfo }: OverviewMapProps) => {
  const { t } = useTranslation();
  const { map } = useContext(ImageMapContext);

  useEffect(() => {
    if (!map || !tileLayerInfo) {
      return;
    }
    const source = new Zoomify({
      url: tilesUrl,
      size: [tileLayerInfo.width, tileLayerInfo.height],
      crossOrigin: 'anonymous',
      zDirection: -1,
    });

    const extent = source.getTileGrid()?.getExtent();

    const projection = new ProjectionOl({
      code: 'DICOM',
      global: true,
      extent: extent,
      getPointResolution: pixelRes => (pixelRes * pixelSpacing) / 10 ** 3,
    });

    const tileLayer = new TileLayerOl({
      source: source,
    });

    const center = getCenter(projection.getExtent());

    const overviewMap = new OverviewMapOl({
      view: new ViewOl({
        projection: projection,
        constrainOnlyCenter: true,
        resolutions: [256],
        extent: center.concat(center),
        showFullExtent: true,
      }),
      layers: [tileLayer],
      collapsed: false,
      tipLabel: t('images:controls.overviewMap'),
    });

    if (extent) {
      const viewport = map.getViewport();
      const viewportHeight = viewport.clientHeight;
      const viewportWidth = viewport.clientWidth;
      const viewportHeightFraction = 0.4;
      const viewportWidthFraction = 0.2;
      const targetHeight = viewportHeight * viewportHeightFraction;
      const targetWidth = viewportWidth * viewportWidthFraction;

      let width = 0;
      let height = 0;
      let resolution = 0;

      if (targetHeight > targetWidth) {
        width = targetWidth;
        height = (width * getHeight(extent)) / getWidth(extent);
        resolution = getWidth(extent) / width;
      } else {
        height = targetHeight;
        width = (height * getWidth(extent)) / getHeight(extent);
        resolution = getHeight(extent) / height;
      }

      setOverviewMapInfo({ width: width.toString().concat('px'), height: height.toString().concat('px') });

      const center = getCenter(extent);
      const overviewView = new ViewOl({
        projection: projection,
        constrainOnlyCenter: true,
        minResolution: resolution,
        maxResolution: resolution,
        extent: center.concat(center),
        showFullExtent: true,
      });

      overviewMap.getOverviewMap().setView(overviewView);
    }

    map.addControl(overviewMap);

    return () => {
      map.removeControl(overviewMap);
    };
  }, [t, setOverviewMapInfo, map, pixelSpacing, tileLayerInfo, tilesUrl]);

  return null;
};
