import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View as ViewOl } from 'ol';
import { OverviewMap as OverviewMapOl } from 'ol/control';
import { getCenter, getHeight, getWidth } from 'ol/extent';
import { Image as ImageLayerOl } from 'ol/layer';
import { ImageStatic } from 'ol/source';

import type { IImageLayerInfo } from '../types';

import { ImageMapContext } from '../ImageMapContext';

interface OverviewMapJpgBaseProps {
  jpgUrl: string;
  imageLayerInfo: IImageLayerInfo;
  handleOverviewMapInfo: (height: number, width: number) => void;
}

export const OverviewMapJpgBase = ({ jpgUrl, imageLayerInfo, handleOverviewMapInfo }: OverviewMapJpgBaseProps) => {
  const { t } = useTranslation();
  const { map } = useContext(ImageMapContext);

  useEffect(() => {
    if (!map) {
      return;
    }
    const extent = [0, 0, imageLayerInfo.width, imageLayerInfo.height];
    const projection = map.getView().getProjection();

    const source = new ImageStatic({
      url: jpgUrl,
      projection: projection,
      imageExtent: extent,
    });

    const tileLayer = new ImageLayerOl({
      source: source,
    });

    const viewport = map.getViewport();
    const { clientHeight, clientWidth } = viewport;
    const viewportFraction = 0.3;
    const targetHeight = clientHeight * viewportFraction;
    const targetWidth = clientWidth * viewportFraction;

    let resolution = 0;

    if (targetHeight < targetWidth) {
      const height = (targetWidth * getHeight(extent)) / getWidth(extent);
      handleOverviewMapInfo(height + height * 0.2, targetWidth + targetWidth * 0.1);
      resolution = getWidth(extent) / targetWidth;
    } else {
      const width = (targetHeight * getWidth(extent)) / getHeight(extent);
      handleOverviewMapInfo(targetHeight + targetHeight * 0.1, width + width * 0.2);
      resolution = getHeight(extent) / targetHeight;
    }

    const center = getCenter(extent);
    const overviewMap = new OverviewMapOl({
      view: new ViewOl({
        projection: projection,
        constrainOnlyCenter: true,
        minResolution: resolution,
        maxResolution: resolution,
        extent: center.concat(center),
      }),
      layers: [tileLayer],
      collapsed: false,
      tipLabel: t('images:controls.overviewMap'),
    });

    map.addControl(overviewMap);

    return () => {
      if (map) {
        map.removeControl(overviewMap);
      }
    };
  }, [imageLayerInfo, jpgUrl, map, t, handleOverviewMapInfo]);

  return null;
};
