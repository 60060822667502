import http from 'helpers/http';

import type { IPagination } from 'models/global';
import type { IApiKeyAdminPanel, IApiKeyAdminPanelList, IApiKeyAdminPanelRequest, IUserAdminPanel } from './types';
import type { IUser } from 'models/Auth';

import { UsersFilters } from 'models/UsersFilters';
import { ApiKeysFilters } from 'models/ApiKeysFilters';

export const serviceAdminPanel = {
  getUsers: (params: UsersFilters) => http.get<IPagination<IUserAdminPanel>>(`/users?${params.searchParams()}`),
  grantAdmin: (userId: string) => http.put<IUser>(`/users/grant-admin/${userId}`),
  revokeAdmin: (userId: string) => http.put<IUser>(`/users/revoke-admin/${userId}`),
  activateUser: (userId: string) => http.put<IUser>(`/users/activate-user/${userId}`),
  blockUser: (userId: string) => http.put<IUser>(`/users/block-user/${userId}`),
  unblockUser: (userId: string) => http.put<IUser>(`/users/unblock-user/${userId}`),
  deleteUser: (userId: string) => http.delete<IUser>(`/users/${userId}`),
  getApiKeys: (params: ApiKeysFilters) =>
    http.get<IPagination<IApiKeyAdminPanelList>>(`/api-keys-admin?${params.searchParams()}`),
  getApiKey: (apiKeyId: string) => http.get<IApiKeyAdminPanel>(`/api-keys-admin/${apiKeyId}`),
  generateApiKey: (apiKeyId: string) => http.put<IApiKeyAdminPanelRequest>(`/api-keys-admin/generate/${apiKeyId}`),
  declineApiKey: (apiKeyId: string) => http.put<IApiKeyAdminPanelRequest>(`/api-keys-admin/decline/${apiKeyId}`),
  blockApiKey: (apiKeyId: string) => http.put<IApiKeyAdminPanelRequest>(`/api-keys-admin/block/${apiKeyId}`),
  unblockApiKey: (apiKeyId: string) => http.put<IApiKeyAdminPanelRequest>(`/api-keys-admin/unblock/${apiKeyId}`),
  deleteApiKey: (apiKeyId: string) => http.delete<IApiKeyAdminPanelRequest>(`/api-keys-admin/delete/${apiKeyId}`),
};
