import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Row } from 'antd';

import type { IApiKeyAdminPanel } from '../../types';

import { Form } from 'components/Form/Form';
import { PageLayout } from 'components/PageLayout';
import { Loading } from 'components/Loading';
import { Container } from 'config/globalStyles';
import { PendingApiKey } from './PendingApiKey';
import { AssignedApiKey } from './AssignedApiKey';

import { useApiKeyQuery } from '../../queries';
import { useWebTitle } from 'hooks/useWebTitle';
import { formatIsoToDisplay } from 'helpers/dates';
import { routes } from 'app/router/routes';

import { ApiKeyStatus, DataPurpose } from 'pages/AccountPanel/types';

import { AdminPanelIcon } from 'icons';
import { Title } from '../../styles';

export interface IDataPurposeSelect {
  label: string;
  value: DataPurpose;
}

export const ApiKeysDetails = (): JSX.Element => {
  const { id } = useParams();
  const { data: apiKey, isLoading, isFetching } = useApiKeyQuery(id ?? '');
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IApiKeyAdminPanel>({});
  const { t } = useTranslation();

  useWebTitle('apiKeysDetails', apiKey?.name);
  const options: IDataPurposeSelect[] = [];

  Object.values(DataPurpose).forEach(dataPurpose => {
    const option = { label: t(`adminPanel:apiKeys.dataPurposeOptions.${dataPurpose}`), value: dataPurpose };
    options.push(option);
  });

  useEffect(() => {
    if (apiKey) {
      setValue('name', apiKey.name);
      setValue('email', apiKey.email);
      setValue('dataPurpose', apiKey.dataPurpose);
      setValue('dataPurposeOtherDescription', apiKey.dataPurposeOtherDescription);
      setValue('companyName', apiKey.companyName);
      setValue('jobTitle', apiKey.jobTitle);
      setValue('createdAt', formatIsoToDisplay(apiKey.createdAt));
      setValue('updatedAt', formatIsoToDisplay(apiKey.updatedAt));
      setValue('status', t(`adminPanel:apiKeys.apiKeyStatus.${apiKey.status}`) as ApiKeyStatus);
    }
  }, [t, setValue, apiKey]);

  const items = [
    { icon: <AdminPanelIcon />, label: t('menu:adminPanel'), link: routes.apiKeys() },
    { label: t('menu:apiKeyAdmin') },
  ];

  return (
    <PageLayout items={items}>
      <Container size='normal'>
        <Title>{t('adminPanel:apiKeys.detailsTitle')}</Title>
        <Loading loading={isLoading || isFetching}>
          <Form<IApiKeyAdminPanel>>
            <Row gutter={16}>
              {apiKey &&
                (apiKey.status === ApiKeyStatus.PENDING ? (
                  <PendingApiKey
                    apiKeyId={apiKey._id}
                    control={control}
                    errors={errors}
                    dataPurposeOptions={options}
                    watch={watch}
                  />
                ) : (
                  <AssignedApiKey
                    apiKeyId={apiKey._id}
                    apiKeyStatus={apiKey.status}
                    control={control}
                    errors={errors}
                    dataPurposeOptions={options}
                    watch={watch}
                  />
                ))}
            </Row>
          </Form>
        </Loading>
      </Container>
    </PageLayout>
  );
};
