import { Col } from 'antd';
import { useTranslation } from 'react-i18next';

import type { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import type { IApiKeyAdminPanel } from 'pages/AdminPanel/types';
import type { IDataPurposeSelect } from '.';

import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { ApiKeyStatus, DataPurpose } from 'pages/AccountPanel/types';

import { useBlockApiKeyMutation, useDeleteApiKeyMutation, useUnlockApiKeyMutation } from 'pages/AdminPanel/queries';

import { ActionButton, ActionButtonsContainer, FormNotClickable } from '../../styles';

export interface AssignedApiKeyProps {
  apiKeyId: string;
  apiKeyStatus: ApiKeyStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IApiKeyAdminPanel, any>;
  errors: FieldErrors<IApiKeyAdminPanel>;
  dataPurposeOptions: IDataPurposeSelect[];
  watch: UseFormWatch<IApiKeyAdminPanel>;
}

export const AssignedApiKey = ({ apiKeyId, apiKeyStatus, control, errors, dataPurposeOptions, watch }: AssignedApiKeyProps) => {
  const { t } = useTranslation();
  const { mutate: blockApiKey } = useBlockApiKeyMutation();
  const { mutate: unblockApiKey } = useUnlockApiKeyMutation();
  const { mutate: deleteApiKey } = useDeleteApiKeyMutation();

  const handleBlockUnblock = () => {
    switch (apiKeyStatus) {
      case ApiKeyStatus.BLOCKED:
        unblockApiKey(apiKeyId);
        break;

      case ApiKeyStatus.GRANTED:
        blockApiKey(apiKeyId);
        break;

      default:
        return;
    }
  };

  return (
    <>
      <Col md={12} xs={24}>
        <FormNotClickable>
          <Input
            name='status'
            label={t('adminPanel:apiKeys.status')}
            control={control}
            errors={errors}
            restProps={{ readOnly: true }}
          />
          <Input
            name='updatedAt'
            label={t('adminPanel:apiKeys.decisionDate')}
            control={control}
            errors={errors}
            restProps={{ readOnly: true }}
          />
        </FormNotClickable>
        <ActionButtonsContainer>
          <ActionButton type='text' danger onClick={() => deleteApiKey(apiKeyId)}>
            {t('adminPanel:apiKeys.delete')}
          </ActionButton>
          <ActionButton type='text' onClick={handleBlockUnblock}>
            {apiKeyStatus === ApiKeyStatus.BLOCKED ? t('adminPanel:apiKeys.unblock') : t('adminPanel:apiKeys.block')}
          </ActionButton>
        </ActionButtonsContainer>
      </Col>
      <Col md={12} xs={24}>
        <FormNotClickable>
          <Input
            name='name'
            label={t('adminPanel:apiKeys.name')}
            control={control}
            errors={errors}
            restProps={{ readOnly: true }}
          />
          <Input
            name='email'
            label={t('adminPanel:apiKeys.email')}
            control={control}
            errors={errors}
            restProps={{ readOnly: true }}
          />
          <Select
            name='dataPurpose'
            label={t('adminPanel:apiKeys.dataPurpose')}
            control={control}
            errors={errors}
            options={dataPurposeOptions}
            restProps={{ mode: 'multiple', open: false, showSearch: false }}
          />
          {watch('dataPurpose')?.includes(DataPurpose.OTHERS) && (
            <Input
              name='dataPurposeOtherDescription'
              label={t('adminPanel:apiKeys.dataPurposeOtherDescription')}
              control={control}
              errors={errors}
            />
          )}
          <Input
            name='companyName'
            label={t('adminPanel:apiKeys.companyName')}
            control={control}
            errors={errors}
            optional
            restProps={{ readOnly: true }}
          />
          <Input
            name='jobTitle'
            label={t('adminPanel:apiKeys.jobTitle')}
            control={control}
            errors={errors}
            optional
            restProps={{ readOnly: true }}
          />
          <Input
            name='createdAt'
            label={t('adminPanel:apiKeys.applicationDate')}
            control={control}
            errors={errors}
            restProps={{ readOnly: true }}
          />
        </FormNotClickable>
      </Col>
    </>
  );
};
