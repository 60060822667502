import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';

import { App } from 'app/App';

import './config/i18n';

if (process.env.REACT_APP_GA_TRACKING_CODE) ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

const container = document.getElementById('app') as Element;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
