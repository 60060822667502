import { DownOutlined } from '@ant-design/icons';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapseIconStyled } from '../styles';

interface CollapseIconProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export const CollapseIcon = ({ collapsed, setCollapsed }: CollapseIconProps): JSX.Element => {
  const { t } = useTranslation();

  const onClick = (ev: MouseEvent<HTMLDivElement>) => {
    ev.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <CollapseIconStyled onClick={onClick} collapsed={collapsed}>
      <DownOutlined aria-label={collapsed ? t('images:collapseDown') : t('images:collapseUp')} />
    </CollapseIconStyled>
  );
};
