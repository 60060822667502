import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Filters } from './Filters';
import { ListItem } from './ListItem';
import { ListHeader } from './ListHeader';
import { loadingIndicator } from 'components/Loading';
import { SrOnly } from 'components/SrOnly/style';

import { usePagination } from 'hooks/usePagination';
import { useImagesQuery } from '../queries';

import { ImagesListStyled, Title } from '../styles';

export const ImagesList = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { data: images, isLoading } = useImagesQuery(searchParams);
  const pagination = usePagination(images?.total);

  return (
    <ImagesListStyled>
      <SrOnly>
        <Title>{t('images:title')}</Title>
      </SrOnly>
      <Filters />
      <ListHeader />
      <List
        dataSource={images?.data ?? []}
        loading={{ indicator: loadingIndicator, spinning: isLoading }}
        renderItem={item => <ListItem item={item} />}
        pagination={images ? pagination : undefined}
      />
    </ImagesListStyled>
  );
};
