import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { commonFocusCss } from 'config/globalStyles';

interface CollapsedProps {
  collapsed: boolean;
}

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.8rem;
  margin: 0 0 1.6rem 0;
`;

export const LinkAccessible = styled(Link)`
  &:focus {
    section:first-child {
      ${commonFocusCss}
    }
  }
`;

export const ImagesListStyled = styled.div`
  .ant-pagination {
    li,
    .ant-pagination-prev button,
    .ant-pagination-next button,
    .ant-select-single .ant-select-selector {
      border: 0;
      border-radius: 2px;
      box-shadow: ${props => props.theme.shadow};
    }

    .ant-select-arrow {
      color: ${props => props.theme.palette.primary.fontColor};
    }

    .ant-pagination-item-active {
      background-color: ${props => props.theme.palette.secondary.main};
      font-weight: 600;
    }

    .ant-select-item-option-selected {
      background-color: ${props => props.theme.palette.secondary.main};
    }

    .ant-pagination-item,
    .ant-pagination-item-link,
    .ant-pagination-prev,
    .ant-pagination-next {
      &:focus {
        ${commonFocusCss}
      }
    }

    .ant-pagination-jump-next + li {
      display: none;
    }
  }
`;

export const FiltersLabel = styled.div`
  color: ${props => props.theme.palette.primary.fontColor};
  line-height: 4rem;
  font-weight: 600;
`;

export const ListHeaderStyled = styled(Col)`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 5rem;
  margin: 2rem 0 1rem;
`;

export const ListItemStyled = styled.section<CollapsedProps>`
  overflow: hidden;
  margin-bottom: 0.4rem;
  box-shadow: ${props => props.theme.shadow};
  border-radius: 12px;
  width: 100%;

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const ListItemHeaderStyled = styled.div`
  background-color: ${props => props.theme.palette.common.white};
  line-height: 8.5rem;
  position: relative;

  h2 {
    margin: 0;
    padding-right: 2rem;
    font-size: inherit;
    font-weight: inherit;
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: ${props => props.theme.breakpoints.medium}) {
      text-overflow: break-word;
      white-space: normal;
      line-height: normal;
      align-items: normal;

      margin-bottom: 1rem;
    }
  }

  .download-column {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    padding: 2rem 0rem 2rem 0rem;
    line-height: normal;
  }
`;

export const ListItemPanelStyled = styled.div`
  background-color: ${props => props.theme.palette.primary.fontColor};

  padding: 2rem 2rem 2rem 8.33%;

  h3 {
    color: ${props => props.theme.palette.common.white};
    font-size: 1.6rem;
    margin: 0;
  }

  .ant-typography {
    color: ${props => props.theme.palette.primary.main};
    margin: 0;
  }
`;

export const CollapseIconStyled = styled.div<CollapsedProps>`
  text-align: center;
  transition: transform 0.3s linear;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    background-color: red;
  }

  ${props =>
    !props.collapsed
      ? `
      transform: rotate(180deg);
    `
      : null}
`;

export const ListItemImageStyled = styled.img`
  object-fit: cover;
  object-position: center;
  height: 6.5rem;
  width: 12rem;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    height: 8rem;
    width: 14rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 9rem;
    height: 6.3rem;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;

  .ant-input-affix-wrapper,
  .ant-select {
    min-width: 248px;
  }

  .ant-select-clear {
    opacity: 1;
  }

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    flex-direction: column;
    width: 100%;

    .ant-form-item {
      margin-bottom: 5px;
    }
  }
`;

export const ListHeaderContainer = styled(Row)`
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const LabelContainer = styled(Col)`
  margin-right: 2rem;
`;

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

export const PropertyName = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  display: none;
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    display: inline;
  }
`;

export const PropertyCol = styled(Col)`
  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
`;

export const ListItemRow = styled(Row)``;

export const InformationCol = styled(Col)`
  font-size: 1.5rem;
`;

export const PropertiesCol = styled(Col)`
  text-overflow: ellipsis;
`;

export const MainRow = styled(Row)``;
