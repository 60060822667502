import { useTranslation } from 'react-i18next';

import { EMAIL_PATTERN, PASSWORD_PATTERN } from 'config/patterns';

interface ValidationOptions {
  required: undefined;
  email: undefined;
  password: undefined;
  minLength: number;
}

export const useValidation = () => {
  const { t } = useTranslation();

  const requiredField = {
    required: {
      value: true,
      message: t('validation:required'),
    },
  };

  const validationFn = <K extends keyof ValidationOptions>(key: K, options?: ValidationOptions[K]) => {
    switch (key) {
      case 'required':
        return requiredField;
      case 'email':
        return {
          ...requiredField,
          minLength: {
            value: 5,
            message: t('validation:minLength', { minLength: 5 }),
          },
          pattern: {
            value: EMAIL_PATTERN,
            message: t('validation:incorrectEmail'),
          },
        };
      case 'password':
        return {
          ...requiredField,
          pattern: {
            value: PASSWORD_PATTERN,
            message: t('validation:incorrectPassword'),
          },
        };
      case 'minLength': {
        const minLength = options ?? 3;
        return {
          minLength: {
            value: minLength,
            message: t('validation:minLength', { minLength }),
          },
        };
      }
    }
  };

  return { validate: validationFn };
};
