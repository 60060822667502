import { CopyOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import styled, { css } from 'styled-components';

const commonPlaceholderStyles = css`
  line-height: 4rem;
  color: ${({ theme }) => theme.palette.common.placeholder};
`;

const commonInputStyles = css`
  box-shadow: ${props => props.theme.shadow};
  border-radius: 8px;
  padding: 0 1rem;
  font-size: 1.6rem;
  height: 4rem;
  line-height: inherit;
`;

export const StyledInput = styled(Input)`
  ${commonInputStyles}

  & > ::placeholder {
    ${commonPlaceholderStyles}
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  ${commonInputStyles}

  & > ::placeholder {
    ${commonPlaceholderStyles}
  }
`;

export const StyledInputPassword = styled(Input.Password)`
  ${commonInputStyles}

  & > ::placeholder {
    ${commonPlaceholderStyles}
  }
`;

export const StyledSelect: typeof Select = styled(Select)`
  &.ant-select .ant-select-selector {
    box-shadow: ${props => props.theme.shadow};
    border-radius: 8px;
    padding: 0 1rem;
    font-size: 1.6rem;
    min-height: 4rem;
    line-height: 4rem;

    .ant-select-selection-item {
      line-height: 4rem;
    }

    .ant-select-selection-item-content,
    .ant-select-selection-item-remove {
      line-height: 24px;
    }

    .ant-select-selection-item-content {
      font-size: 1.2rem;
    }

    .ant-select-selection-placeholder {
      ${commonPlaceholderStyles}
    }
  }

  .ant-select-arrow {
    color: ${props => props.theme.palette.primary.fontColor};
  }
`;

export const StyledFormItem = styled(Form.Item)`
  line-height: 4rem;

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    //* INFO: antd selectors have high specificity
    border-color: ${({ theme }) => theme.palette.common.newError} !important;
  }

  .ant-input-status-error,
  .ant-input-affix-wrapper-status-error {
    //* INFO: antd selectors have high specificity
    border-color: ${({ theme }) => theme.palette.common.newError} !important;
  }

  .ant-form-item-explain > .ant-form-item-explain-error {
    color: ${({ theme }) => theme.palette.common.newError};
  }

  label > .ant-form-item-optional {
    color: ${props => props.theme.font.color.black_60};
  }
`;

export const StyledInputPasswordWithCopyContainer = styled.div`
  position: relative;

  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
  align-items: center;
`;

export const CopyIcon = styled(CopyOutlined)`
  position: absolute;
  margin-right: 1rem;
  z-index: 999;

  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    color: rgba(0, 0, 0, 0.85);
  }
`;

export const StyledInputPasswordWithCopy = styled(Input.Password)`
  ${commonInputStyles}
  flex-grow: 1;

  & > ::placeholder {
    ${commonPlaceholderStyles}
  }

  .ant-input-suffix {
    padding-right: 2rem;
  }

  .ant-input-wrapper {
    height: 100%;
    border-radius: 12px;
  }
`;
