import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useForm } from 'react-hook-form';

import type { SubmitHandler } from 'react-hook-form';
import type { IPasswordChange } from '../types';

import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { PageLayout } from 'components/PageLayout';
import { RadioNavigation } from 'components/RadioNavigation';
import { Container } from 'config/globalStyles';

import { usePasswordChangeMutation } from '../queries';
import { useValidation } from 'hooks/useValidation';
import { useWebTitle } from 'hooks/useWebTitle';
import { routes } from 'app/router/routes';

import { ActionButton, ActionButtonsContainer } from '../style';
import { UserAccountIcon } from 'icons';

export const PasswordChange = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<IPasswordChange>();
  const { t } = useTranslation();
  const { validate } = useValidation();
  const { mutate: basicInformationSubmit, isPending } = usePasswordChangeMutation();
  useWebTitle('passwordChange');

  const onSubmit: SubmitHandler<IPasswordChange> = data => {
    basicInformationSubmit(data);
  };

  const items = [{ icon: <UserAccountIcon />, label: t('menu:userSettings') }];

  const navigationItems = [
    {
      title: t('accountPanel:basicInformation.title'),
      to: routes.basicInformation(),
    },
    {
      title: t('accountPanel:passwordChange.title'),
      to: routes.passwordChange(),
      active: true,
    },
    {
      title: t('accountPanel:apiKey.title'),
      to: routes.apiKey(),
    },
  ];

  return (
    <PageLayout items={items}>
      <Container size='normal'>
        <RadioNavigation items={navigationItems} />
        <Form<IPasswordChange> onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12} md={18} xs={24}>
              <Input.Password
                name='oldPassword'
                label={t('accountPanel:passwordChange.oldPassword')}
                control={control}
                errors={errors}
                rules={validate('required')}
              />
              <Input.Password
                name='password'
                label={t('accountPanel:passwordChange.password')}
                control={control}
                errors={errors}
                rules={validate('password')}
              />
              <Input.Password
                name='passwordConfirm'
                label={t('accountPanel:passwordChange.passwordConfirm')}
                control={control}
                errors={errors}
                rules={{
                  ...validate('required'),
                  validate: (val: string | undefined) => {
                    if (watch('password') !== val) {
                      return t('validation:passwordConfirmError');
                    }
                  },
                }}
              />

              <ActionButtonsContainer>
                <ActionButton type='primary' htmlType='submit' loading={isPending}>
                  {t('accountPanel:passwordChange.save')}
                </ActionButton>
              </ActionButtonsContainer>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageLayout>
  );
};
