import { redirect } from 'react-router-dom';

import type { LoaderFunction } from 'react-router-dom';

import { routes } from './routes';
import { serviceAuth } from 'pages/Auth/service';
import { getAuthToken } from 'helpers/auth';
import { UserRole } from 'config/userRole';

export const requireUserSession: LoaderFunction = async () => {
  try {
    const token = getAuthToken();
    if (token) {
      await serviceAuth.whoAmI();
      return null;
    }

    return redirect(routes.login());
  } catch (error) {
    return redirect(routes.login());
  }
};

export const requireAdminSession: LoaderFunction = async () => {
  try {
    const token = getAuthToken();
    if (token) {
      const { role } = await serviceAuth.whoAmI();

      if (role === UserRole.ADMIN) {
        return null;
      }

      return redirect(routes.login());
    }

    return redirect(routes.noAccess());
  } catch (error) {
    return redirect(routes.noAccess());
  }
};
