import { Controller } from 'react-hook-form';
import get from 'lodash.get';

import type { InputProps as InputAntdProps } from 'antd';
import type { FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';

import { TextArea } from './TextArea';
import { Search } from './Search';
import { Password } from './Password';
import { PasswordWithCopy } from './PasswordWithCopy';
import { Group } from './Group';
import { Number } from './Number';
import { SrOnly } from 'components/SrOnly/style';

import { StyledFormItem, StyledInput } from '../styles';

interface InputProps<T extends FieldValues> extends UseControllerProps<T> {
  label?: string;
  errors: FieldErrors<T>;
  restProps?: InputAntdProps;
  optional?: boolean;
}

export const Input = <T extends FieldValues>({
  name,
  label,
  errors,
  control,
  rules,
  restProps,
  optional,
}: InputProps<T>): JSX.Element => (
  <>
    <StyledFormItem
      name={name}
      label={label}
      required={optional ? false : true}
      validateStatus={get(errors, name) ? 'error' : ''}
      help={<>{get(errors, name)?.message}</>}
    >
      <>
        {!label && (
          <SrOnly>
            <label htmlFor={name}>{restProps?.placeholder}</label>
          </SrOnly>
        )}
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => <StyledInput {...field} id={name} {...restProps} aria-required={optional ? false : true} />}
        />
      </>
    </StyledFormItem>
  </>
);

Input.TextArea = TextArea;
Input.Search = Search;
Input.Password = Password;
Input.PasswordWithCopy = PasswordWithCopy;
Input.Group = Group;
Input.Number = Number;
