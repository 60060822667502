import { useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { Size, useWindowSize } from '../../../hooks/useWindowSize';
import { Image } from 'models/Image';
import { ListItemHeaderStyled, PropertyName, PropertyCol, ListItemRow, InformationCol, PropertiesCol, MainRow } from '../styles';
import { CollapseIcon } from './CollapseIcon';
import { DownloadButton } from '../../../components/DownloadButton';
import { ListItemImage } from './ListItemImage';

export interface ListItemHeaderProps {
  item: Image;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export const ListItemHeader = ({ item, collapsed, setCollapsed }: ListItemHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  const size: Size = useWindowSize();

  const isMobileResolution: boolean = useMemo(() => (size?.width ? size.width < 768 : false), [size.width]);

  return (
    <ListItemHeaderStyled>
      <ListItemRow>
        <Col md={2} xs={2}>
          <CollapseIcon collapsed={collapsed} setCollapsed={setCollapsed} />
        </Col>

        <InformationCol xs={20} md={20}>
          <MainRow wrap={true}>
            <Col md={10} xs={24}>
              <Typography.Title level={2} ellipsis={isMobileResolution ? { rows: 2 } : true}>
                {item.diagnosis}
              </Typography.Title>
            </Col>

            <PropertiesCol md={10} xs={16}>
              <Row>
                <PropertyCol md={14} xs={24}>
                  <PropertyName>{t('images:stain')}: </PropertyName>
                  {item.stain}
                </PropertyCol>
                <PropertyCol md={10} xs={24}>
                  <PropertyName>{t('images:tissue')}: </PropertyName>
                  {item.tissue}
                </PropertyCol>
              </Row>
            </PropertiesCol>

            <PropertyCol md={4} xs={8}>
              <ListItemImage image={item.thumbPath} />
            </PropertyCol>
          </MainRow>
        </InformationCol>

        <Col className='download-column' xs={2}>
          <DownloadButton
            id={item.id}
            direction='vertical'
            sizeDicom={item.dicomDownloadSize}
            sizeJpg={item.jpgDownloadSize}
            tooltipPlacement='left'
          />
        </Col>
      </ListItemRow>
    </ListItemHeaderStyled>
  );
};
