import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface LoadingProps {
  loading: boolean;
  children: React.ReactNode;
}

export const loadingIndicator = <LoadingOutlined spin />;

export const Loading = ({ loading, children }: LoadingProps): JSX.Element => (
  <>
    {loading ? (
      <LoadingContainer>
        <Spin indicator={loadingIndicator} />
      </LoadingContainer>
    ) : (
      children
    )}
  </>
);

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
`;
