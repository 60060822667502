import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { useForm } from 'react-hook-form';

import { IUsersFilters } from 'models/UsersFilters';

import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';

import { UserRole } from 'config/userRole';
import { ApiKeyUserStatus, UserStatus } from '../types';

import { FiltersContainer } from '../styles';

export const Filters = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IUsersFilters>({
    defaultValues: {},
  });

  useEffect(() => {
    const roleParam = searchParams.get('role') ?? '';
    const statusParam = searchParams.get('status') ?? '';
    const apiKeyParam = searchParams.get('apiKey') ?? '';

    setValue('search', searchParams.get('search') ?? undefined);
    setValue('role', Object.values(UserRole).includes(roleParam as UserRole) ? roleParam : undefined);
    setValue('status', Object.values(UserStatus).includes(statusParam as UserStatus) ? statusParam : undefined);
    setValue('apiKey', Object.values(ApiKeyUserStatus).includes(apiKeyParam as ApiKeyUserStatus) ? apiKeyParam : undefined);
  }, [searchParams, setValue]);

  const onSearchChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.search;
    }
    setSearchParams({ ...oldValues, search: newValue, page: '1' });
  };

  const onRoleChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.role;
      setSearchParams({ ...oldValues, page: '1' });
      return;
    }
    setSearchParams({ ...oldValues, role: newValue, page: '1' });
  };

  const onStatusChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.status;
      setSearchParams({ ...oldValues, page: '1' });
      return;
    }
    setSearchParams({ ...oldValues, status: newValue, page: '1' });
  };

  const onApiKeyChange = (newValue: string) => {
    const oldValues = Object.fromEntries(searchParams);
    if (!newValue) {
      delete oldValues.apiKey;
      setSearchParams({ ...oldValues, page: '1' });
      return;
    }
    setSearchParams({ ...oldValues, apiKey: newValue, page: '1' });
  };

  const roleOptions = Object.values(UserRole).map(value => ({
    label: t(`adminPanel:users.userRole.${value}`, { defaultValue: '' }),
    value,
  }));

  const statusOptions = Object.values(UserStatus).map(value => ({
    label: t(`adminPanel:users.userStatus.${value}`, { defaultValue: '' }),
    value,
  }));

  const apiKeyOptions = Object.values(ApiKeyUserStatus).map(value => ({
    label: t(`adminPanel:users.apiKeyStatus.${value}`, { defaultValue: '' }),
    value,
  }));

  return (
    <Form<IUsersFilters>>
      <Row gutter={16}>
        <FiltersContainer>
          <Col>
            <Input
              name='search'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: event => onSearchChange(event.target.value),
                placeholder: t('adminPanel:users.nameOrEmail'),
                allowClear: true,
              }}
            />
          </Col>
          <Col>
            <Select
              name='role'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: value => onRoleChange(value),
                placeholder: t('adminPanel:users.role'),
                allowClear: true,
                showArrow: watch('role') ? false : true,
              }}
              options={roleOptions}
            />
          </Col>
          <Col>
            <Select
              name='status'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: value => onStatusChange(value),
                placeholder: t('adminPanel:users.status'),
                allowClear: true,
                showArrow: watch('status') ? false : true,
              }}
              options={statusOptions}
            />
          </Col>
          <Col>
            <Select
              name='apiKey'
              control={control}
              errors={errors}
              optional={true}
              restProps={{
                onChange: value => onApiKeyChange(value),
                placeholder: t('adminPanel:users.apiKey'),
                allowClear: true,
                showArrow: watch('apiKey') ? false : true,
              }}
              options={apiKeyOptions}
            />
          </Col>
        </FiltersContainer>
      </Row>
    </Form>
  );
};
