import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import type { IUser } from 'models/Auth';

import { routes } from 'app/router/routes';
import { removeAuthToken } from 'helpers/auth';
import { UserRole } from 'config/userRole';
import { KeyIcon, UserAccountIcon, AdminPanelIcon, LogoutIcon } from 'icons';

export const useUserDropdownItems = (user: IUser | undefined) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeAuthToken();
    navigate(routes.images());
    queryClient.clear();
  };

  const basicInformation = {
    label: <Link to={routes.basicInformation()}>{t('menu:userSettings')}</Link>,
    key: 'account',
    icon: <UserAccountIcon />,
  };

  const apiKey = {
    label: <Link to={routes.apiKey()}>{t('menu:apiKey')}</Link>,
    key: 'apiKey',
    icon: <KeyIcon />,
  };

  const adminPanel = {
    label: <Link to={routes.adminPanel()}>{t('menu:adminPanel')}</Link>,
    key: 'apiKey',
    icon: <AdminPanelIcon />,
  };

  const logout = {
    label: t('auth:logout'),
    key: 'logout',
    icon: <LogoutIcon />,
    onClick: handleLogout,
  };

  const divider = {
    type: 'divider' as const,
  };

  const userItems: MenuProps['items'] = [{ ...basicInformation }, { ...apiKey }, { ...divider }, { ...logout }];

  const adminItems: MenuProps['items'] = [
    { ...basicInformation },
    { ...apiKey },
    { ...adminPanel },
    { ...divider },
    { ...logout },
  ];

  return user ? (user.role === UserRole.ADMIN ? adminItems : userItems) : undefined;
};
