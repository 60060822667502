import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'antd';

import type { SubmitHandler } from 'react-hook-form';
import type { IUserRegister } from 'models/Auth';

import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { PageLayout } from 'components/PageLayout';
import { RadioNavigation } from 'components/RadioNavigation';
import { Container } from 'config/globalStyles';

import { useValidation } from 'hooks/useValidation';
import { useWebTitle } from 'hooks/useWebTitle';
import { useRegisterMutation } from '../queries';
import { routes } from 'app/router/routes';

import { RegisterActionContainer } from './style';
import { ActionButton } from '../style';
import { AccountIcon } from 'icons';

export const Register = (): JSX.Element => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<IUserRegister>();
  const { t } = useTranslation();
  const { validate } = useValidation();
  const { mutate: register, isPending } = useRegisterMutation();
  useWebTitle('register');

  const onSubmit: SubmitHandler<IUserRegister> = data => {
    register(data);
  };

  const items = [{ icon: <AccountIcon />, label: t('menu:userAccount') }];

  const navigationItems = [
    {
      title: t('auth:loginTitle'),
      to: routes.login(),
    },
    {
      title: t('auth:registerTitle'),
      to: routes.register(),
      active: true,
    },
  ];

  return (
    <PageLayout items={items} hideUserDropdown>
      <Container size='normal'>
        <RadioNavigation items={navigationItems} />
        <Form<IUserRegister> onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12} md={18} xs={24}>
              <Input
                name='firstName'
                label={t('auth:firstName')}
                control={control}
                errors={errors}
                rules={validate('required')}
                restProps={{ autoFocus: true }}
              />
              <Input name='lastName' label={t('auth:lastName')} control={control} errors={errors} rules={validate('required')} />
              <Input
                name='email'
                label={t('auth:email')}
                control={control}
                errors={errors}
                rules={validate('email')}
                restProps={{ type: 'email' }}
              />
              <Input.Password
                name='password'
                label={t('auth:passwordRegister')}
                control={control}
                errors={errors}
                rules={validate('password')}
              />
              <Input.Password
                name='passwordConfirm'
                label={t('auth:passwordConfirm')}
                control={control}
                errors={errors}
                rules={{
                  ...validate('required'),
                  validate: (val: string | undefined) => {
                    if (watch('password') !== val) {
                      return t('validation:passwordConfirmError');
                    }
                  },
                }}
              />
              <RegisterActionContainer>
                <ActionButton type='primary' htmlType='submit' loading={isPending}>
                  {t('auth:createAccount')}
                </ActionButton>
              </RegisterActionContainer>
            </Col>
          </Row>
        </Form>
      </Container>
    </PageLayout>
  );
};
