import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { Image } from 'models/Image';
import { ImageFilters } from 'models/ImageFilters';

import { imagesService } from './service';

const getImagesQueryKey = (searchParams: URLSearchParams) => {
  const arr = [];

  const page = searchParams.get('page') ?? '1';
  const pageSize = searchParams.get('pageSize') ?? '10';
  const tissue = searchParams.get('tissue');
  const diagnosis = searchParams.get('diagnosis');
  const supplier = searchParams.get('supplier');

  page && arr.push(page);
  pageSize && arr.push(pageSize);
  tissue && arr.push(tissue);
  diagnosis && arr.push(diagnosis);
  supplier && arr.push(supplier);

  return arr;
};

export const useImagesQuery = (searchParams: URLSearchParams) =>
  useQuery({
    queryKey: ['images', 'list', ...getImagesQueryKey(searchParams)],
    queryFn: () => imagesService.getImages(new ImageFilters(Object.fromEntries(searchParams))),
    select: data => ({
      data: data.docs.map(image => new Image(image)),
      total: data.totalDocs,
    }),
    placeholderData: keepPreviousData,
  });
