import { BacteriaIcon, ChevronDownIcon, CloseIcon, MicroscopeIcon, SampleIcon, UserIcon } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from 'models/Image';

import {
  DetailsContainer,
  DetailsTitle,
  DetailsValue,
  ImageContainerSectionHeader,
  ImageContainerSeparator,
  ImageContainerTitle,
  ImageDetailsButtonStyled,
  ImageDetailsContainerStyled,
} from './styles';

export interface ImageDetailsProps {
  image: Image;
  visibleConnectedImages: boolean;
  visibleMap: boolean;
}

export const ImageDetails = ({ image, visibleConnectedImages, visibleMap }: ImageDetailsProps): JSX.Element | null => {
  const { t } = useTranslation();
  const [visibleContainer, setVisibleContainer] = useState(false);
  const numberFormat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: 2 });

  return (
    <>
      {image ? (
        <>
          <ImageDetailsButtonStyled onClick={() => setVisibleContainer(prevState => !prevState)}>
            {visibleContainer ? <CloseIcon /> : <ChevronDownIcon />}
            {t('images:details')}
          </ImageDetailsButtonStyled>
          <ImageDetailsContainerStyled
            visibleConnectedImages={visibleConnectedImages}
            visibleContainer={visibleContainer}
            visibleMap={visibleMap}
          >
            <ImageContainerTitle>{image.testId}</ImageContainerTitle>

            <DetailsContainer title={t('images:details')} visibleConnectedImages={visibleConnectedImages}>
              <ImageContainerSectionHeader>
                <UserIcon /> {t('images:patient')}
              </ImageContainerSectionHeader>
              <DetailsTitle>{t('images:age')}</DetailsTitle>
              <DetailsValue>{image.age}</DetailsValue>
              <DetailsTitle>{t('images:gender')}</DetailsTitle>
              <DetailsValue>{image.gender}</DetailsValue>
              <ImageContainerSeparator />

              <ImageContainerSectionHeader>
                <BacteriaIcon /> {t('images:diagnosis')}
              </ImageContainerSectionHeader>
              <DetailsTitle>{t('images:diagnosis')}</DetailsTitle>
              <DetailsValue>{image.diagnosis}</DetailsValue>

              <ImageContainerSeparator />
              <ImageContainerSectionHeader>
                <SampleIcon /> {t('images:sample')}
              </ImageContainerSectionHeader>
              <DetailsTitle>{t('images:tissue')}</DetailsTitle>
              <DetailsValue>{image.tissue}</DetailsValue>
              <DetailsTitle>{t('images:stain')}</DetailsTitle>
              <DetailsValue>{image.stain}</DetailsValue>

              <ImageContainerSeparator />
              <ImageContainerSectionHeader>
                <MicroscopeIcon /> {t('images:technology')}
              </ImageContainerSectionHeader>
              <DetailsTitle>{t('images:modelName')}</DetailsTitle>
              <DetailsValue>
                {image.dicomManufacturerModelName} {image.dicomManufacturer}
              </DetailsValue>
              <DetailsTitle>{t('images:size')}</DetailsTitle>
              <DetailsValue>
                {image.dicomImagedVolumeWidth &&
                  image.dicomImagedVolumeHeight &&
                  `${numberFormat.format(image.dicomImagedVolumeWidth)} x ${numberFormat.format(
                    image.dicomImagedVolumeHeight,
                  )} mm`}
              </DetailsValue>
              <DetailsTitle>{t('images:views')}</DetailsTitle>
              <DetailsValue>{image.views}</DetailsValue>
            </DetailsContainer>
          </ImageDetailsContainerStyled>
        </>
      ) : null}
    </>
  );
};

export default ImageDetails;
