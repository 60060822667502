import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import type { PasswordProps as PasswordAntdProps } from 'antd/lib/input';
import type { FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';

import { SrOnly } from 'components/SrOnly/style';
import { CopyIcon, StyledFormItem, StyledInputPasswordWithCopy, StyledInputPasswordWithCopyContainer } from '../styles';

import { successNotification } from 'helpers/notification';

interface PasswordWithCopyProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  errors: FieldErrors<T>;
  restProps?: PasswordAntdProps;
  optional?: boolean;
}

export const PasswordWithCopy = <T extends FieldValues>({
  name,
  label,
  errors,
  control,
  rules,
  restProps,
  optional,
}: PasswordWithCopyProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const handleCopy = () => {
    navigator.clipboard.writeText(control?._formValues.keyValue ?? '');
    successNotification(t('accountPanel:apiKey.keySuccessCopy'));
  };

  return (
    <StyledFormItem
      name={name}
      label={label}
      required={optional ? false : true}
      validateStatus={get(errors, name) ? 'error' : ''}
      help={<>{get(errors, name)?.message}</>}
    >
      <>
        {!label && (
          <SrOnly>
            <label htmlFor={name}>{restProps?.placeholder}</label>
          </SrOnly>
        )}
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <StyledInputPasswordWithCopyContainer>
              <StyledInputPasswordWithCopy {...field} id={name} {...restProps} aria-required={optional ? false : true} />
              <CopyIcon onClick={handleCopy} />
            </StyledInputPasswordWithCopyContainer>
          )}
        />
      </>
    </StyledFormItem>
  );
};
