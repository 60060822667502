import { useTranslation } from 'react-i18next';

import { routes } from 'app/router/routes';
import { FEEDBACK_URL, PRIVACY_URL } from 'config/settings';

import { FooterImage, FooterList, StyledFooter, FooterImageContainer, LinkAccessible, HrefAccessible } from './styles';
import { ExternalLinkIcon } from 'icons';

interface FooterProps {
  reverse?: boolean;
  ueImgSize?: 'normal' | 'small';
}

export const Footer = ({ reverse, ueImgSize = 'normal' }: FooterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledFooter className='footer' reverse={reverse}>
      <FooterList reverse={reverse}>
        <li>
          <LinkAccessible to={routes.contact()}>{t('menu:contact')} </LinkAccessible>
        </li>
        <li>
          <LinkAccessible to={routes.apiKeyInformation()}>{t('menu:apiKey')} </LinkAccessible>
        </li>
        <li>{t('menu:copyright')}</li>
        <li>
          <HrefAccessible href={PRIVACY_URL} target='_blank' rel='noopener'>
            {t('menu:privacy')}
            <ExternalLinkIcon />
          </HrefAccessible>
        </li>
        <li>
          <HrefAccessible href={FEEDBACK_URL} target='_blank' rel='noopener'>
            {t('menu:feedback')}
            <ExternalLinkIcon />
          </HrefAccessible>
        </li>
      </FooterList>
      <FooterImageContainer size={ueImgSize}>
        <FooterImage src='/ue_logo.png' alt={t('menu:ue')} />
      </FooterImageContainer>
    </StyledFooter>
  );
};
