import { Controller } from 'react-hook-form';
import get from 'lodash.get';

import type { TextAreaProps as TextAreaAntdProps } from 'antd/lib/input';
import type { UseControllerProps, FieldErrors, FieldValues } from 'react-hook-form';

import { SrOnly } from 'components/SrOnly/style';
import { StyledFormItem, StyledTextArea } from '../styles';

interface TextAreaProps<T extends FieldValues> extends UseControllerProps<T> {
  label?: string;
  errors: FieldErrors<T>;
  restProps?: TextAreaAntdProps;
  optional?: boolean;
}

export const TextArea = <T extends FieldValues>({
  name,
  label,
  errors,
  control,
  rules,
  restProps,
  optional,
}: TextAreaProps<T>): JSX.Element => (
  <StyledFormItem
    name={name}
    label={label}
    required={optional ? false : true}
    validateStatus={get(errors, name) ? 'error' : ''}
    help={<>{get(errors, name)?.message}</>}
  >
    <>
      {!label && (
        <SrOnly>
          <label htmlFor={name}>{restProps?.placeholder}</label>
        </SrOnly>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <StyledTextArea {...field} id={name} rows={3} {...restProps} aria-required={optional ? false : true} />
        )}
      />
    </>
  </StyledFormItem>
);
