import { GENDER } from 'helpers/enums';

export interface IImageConnected {
  _id: string;
  thumbPath: string | null;
}

export type ImageType = {
  _id: string;
  thumbPath: string | null;
  tilesPath: string | null;
  jpgBasePath: string | null;
  dicomPixelSpacing: number | null;
  dicomDeviceSerialNumber: string | null;
  dicomManufacturer: string | null;
  dicomManufacturerModelName: string | null;
  dicomImagedVolumeWidth: number | null;
  dicomImagedVolumeHeight: number | null;
  dicomSeedCompletedAt: Date | null;
  dicomTilesCompletedAt: Date | null;
  dicomThumbCompletedAt: Date | null;
  jpgCompletedAt: Date | null;
  dicomDownloadSize: number;
  jpgDownloadSize: number;
  birthYear: number;
  harvestYear: number;
  age: number;
  stain: string;
  diagnosis: string;
  gender: GENDER;
  tissue: string;
  testId: string;
  createdAt: string;
  updatedAt: string;
  views: number;
};

export class Image {
  id: string;
  thumbPath: string | null;
  tilesPath: string | null;
  jpgBasePath: string | null;
  dicomPixelSpacing: number | null;
  dicomDeviceSerialNumber: string | null;
  dicomManufacturer: string | null;
  dicomManufacturerModelName: string | null;
  dicomImagedVolumeWidth: number | null;
  dicomImagedVolumeHeight: number | null;
  dicomSeedCompletedAt: Date | null;
  dicomTilesCompletedAt: Date | null;
  dicomThumbCompletedAt: Date | null;
  jpgCompletedAt: Date | null;
  dicomDownloadSize: number;
  jpgDownloadSize: number;
  birthYear: number;
  harvestYear: number;
  age: number;
  stain: string;
  diagnosis: string;
  gender: GENDER;
  tissue: string;
  testId: string;
  createdAt: Date;
  updatedAt: Date;
  views: number;

  constructor(obj: ImageType) {
    this.id = obj._id;
    this.thumbPath = obj.thumbPath;
    this.tilesPath = obj.tilesPath;
    this.jpgBasePath = obj.jpgBasePath;
    this.dicomPixelSpacing = obj.dicomPixelSpacing;
    this.dicomDeviceSerialNumber = obj.dicomDeviceSerialNumber;
    this.dicomManufacturer = obj.dicomManufacturer;
    this.dicomManufacturerModelName = obj.dicomManufacturerModelName;
    this.dicomImagedVolumeWidth = obj.dicomImagedVolumeWidth;
    this.dicomImagedVolumeHeight = obj.dicomImagedVolumeHeight;
    this.dicomSeedCompletedAt = obj.dicomSeedCompletedAt;
    this.dicomTilesCompletedAt = obj.dicomTilesCompletedAt;
    this.dicomThumbCompletedAt = obj.dicomThumbCompletedAt;
    this.jpgCompletedAt = obj.jpgCompletedAt;
    this.dicomDownloadSize = obj.dicomDownloadSize;
    this.jpgDownloadSize = obj.jpgDownloadSize;
    this.birthYear = obj.birthYear;
    this.harvestYear = obj.harvestYear;
    this.age = obj.age;
    this.stain = obj.stain;
    this.diagnosis = obj.diagnosis;
    this.tissue = obj.tissue;
    this.testId = obj.testId;
    this.gender = obj.gender;
    this.views = obj.views;
    this.createdAt = new Date(obj.createdAt);
    this.updatedAt = new Date(obj.updatedAt);
  }

  getCreatedDate() {
    return this.createdAt.toDateString();
  }
}

export interface ImageResponse {
  image: ImageType;
  connectedImages: IImageConnected[];
}
