import { useCallback, useEffect, useRef, useState } from 'react';
import convert from 'xml-js';

import type { IOverviewMapInfo, ITileLayerInfo } from '../types';
import type { IImageConnected } from 'models/Image';

import { Empty } from 'components/Empty';
import { Loading } from 'components/Loading';
import { ImageDetails } from '../../ImageDetails';
import { Map } from '../Map';
import { Layers } from '../Layers';
import { TileLayer } from '../Layers/TileLayer';
import { Controls } from '../Controls';
import { OverviewMap } from '../Controls/OverviewMap';
import { ScaleLine } from '../Controls/ScaleLine';
import { ControlPanel } from '../ControlPanel';

import { TILES_URL } from 'config/settings';
import { Image as ImageModel } from 'models/Image';

import { StyledMap } from '../../styles';

interface TileBaseProps {
  image: ImageModel;
  connectedImages: IImageConnected[];
}

interface IDataAttr {
  HEIGHT: string;
  NUMIMAGES: string;
  NUMTILES: string;
  TILESIZE: string;
  VERSION: string;
  WIDTH: string;
}

export const TileBase = ({ image, connectedImages }: TileBaseProps): JSX.Element => {
  const {
    tilesPath,
    dicomTilesCompletedAt: tilesCompletedAt,
    dicomPixelSpacing: pixelSpacing,
    dicomDownloadSize,
    jpgDownloadSize,
  } = image;
  const [visibleConnectedImages, setVisibleConnectedImages] = useState(false);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [tileLayerInfo, setTileLayerInfo] = useState<ITileLayerInfo>();
  const [overviewMapInfo, setOverviewMapInfo] = useState<IOverviewMapInfo>();
  const [tileLayerLoading, setTileLayerLoading] = useState(true);
  const tilesUrl = `${TILES_URL}/${tilesPath}/`;

  const getTileLayerInfo = useCallback(async () => {
    try {
      const response = await fetch(`${tilesUrl}ImageProperties.xml`);

      if (response.ok) {
        const xmlString = await response.text();
        const data = convert.xml2js(xmlString, { compact: true }) as convert.ElementCompact;

        const tempTileLayerInfo = convertDataAttrTileLayerInfo(data.IMAGE_PROPERTIES._attributes);
        setTileLayerInfo(tempTileLayerInfo);
      }

      setTileLayerLoading(false);
    } catch (err) {
      setTileLayerLoading(false);
    }
  }, [tilesUrl]);

  const convertDataAttrTileLayerInfo = (dataAttr: IDataAttr): ITileLayerInfo => ({
    height: Number.parseInt(dataAttr.HEIGHT),
    width: Number.parseInt(dataAttr.WIDTH),
  });

  useEffect(() => {
    if (!tilesCompletedAt || !pixelSpacing || !tilesPath) {
      setTileLayerLoading(false);
      return;
    }
    getTileLayerInfo();
  }, [getTileLayerInfo, tilesCompletedAt, pixelSpacing, tilesPath]);

  const handleVisibleConnectedImages = () => {
    setVisibleConnectedImages(prevState => !prevState);
  };

  return (
    <StyledMap ref={mapContainerRef} overviewMapInfo={overviewMapInfo}>
      <ImageDetails
        image={image}
        visibleConnectedImages={visibleConnectedImages}
        visibleMap={Boolean(tilesCompletedAt && pixelSpacing && tilesPath)}
      />
      {tilesCompletedAt && pixelSpacing && tilesPath && tileLayerInfo ? (
        <Map>
          <Layers>
            <TileLayer tilesPath={tilesPath} pixelSpacing={pixelSpacing} tilesUrl={tilesUrl} tileLayerInfo={tileLayerInfo} />
          </Layers>
          <Controls>
            <ScaleLine />
            <OverviewMap
              pixelSpacing={pixelSpacing}
              tilesUrl={tilesUrl}
              tileLayerInfo={tileLayerInfo}
              setOverviewMapInfo={setOverviewMapInfo}
            />
          </Controls>
          <ControlPanel
            dicomDownloadSize={dicomDownloadSize}
            jpgDownloadSize={jpgDownloadSize}
            visibleConnectedImages={visibleConnectedImages}
            connectedImages={connectedImages}
            handleVisibleConnectedImages={handleVisibleConnectedImages}
            mapContainerRef={mapContainerRef}
          />
        </Map>
      ) : (
        <Loading loading={tileLayerLoading}>
          <Empty />
        </Loading>
      )}
    </StyledMap>
  );
};
