import { useContext, useEffect } from 'react';
import { View as ViewOl } from 'ol';
import { Image as ImageLayerOl } from 'ol/layer';
import { Projection as ProjectionOl } from 'ol/proj';
import { ImageStatic } from 'ol/source';

import { getCenter } from 'ol/extent';

import type { IImageLayerInfo } from '../types';

import { ImageMapContext } from '../ImageMapContext';

interface ImageLayerProps {
  jpgUrl: string;
  imageLayerInfo: IImageLayerInfo;
}

export const ImageLayer = ({ jpgUrl, imageLayerInfo }: ImageLayerProps) => {
  const { map } = useContext(ImageMapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    const extent = [0, 0, imageLayerInfo.width, imageLayerInfo.height];
    const projection = new ProjectionOl({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });

    const source = new ImageStatic({
      url: jpgUrl,
      projection: projection,
      imageExtent: extent,
    });

    const tileLayer = new ImageLayerOl({
      source: source,
      zIndex: 0,
    });

    map.addLayer(tileLayer);
    map.setView(
      new ViewOl({
        projection: projection,
        center: getCenter(extent),
        extent: extent,
        constrainOnlyCenter: true,
        minZoom: 1,
      }),
    );

    extent && map.getView().fit(extent);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map, jpgUrl, imageLayerInfo]);

  return null;
};
