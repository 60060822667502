// from: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034

import styled from 'styled-components';

export const SrOnly = styled.div<{ focusable?: boolean }>`
  border: 0 !important;

  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;

  ${props =>
    !props.focusable
      ? null
      : `
  &:focus,
  &:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
      clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
  `}
`;
